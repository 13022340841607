import React from "react";
import useRequestCount from "../hooks/useRequestCounts";
import RequestCountCard from "./cards/request-count-card";

export default function RequestCounts() {
  const { serviceRequestCounts ,productRequestCounts} = useRequestCount();
  return (
    <div className="row">
      <h5 className="ms-2">Service Requests</h5>
      <RequestCountCard
        title={"New Requests"}
        count={
          serviceRequestCounts.pendingRequests
            ? serviceRequestCounts.pendingRequests
            : "0"
        }
      />

      <RequestCountCard
        title={"Assigned Requests"}
        count={
          serviceRequestCounts.assignedRequests
            ? serviceRequestCounts.assignedRequests
            : "0"
        }
      />
      <RequestCountCard
        title={"Inprogress Requests"}
        count={isNaN(serviceRequestCounts.inprogressRequests)?0:serviceRequestCounts.completedRequests}
      />
      <RequestCountCard
        title={"Completed Requests"}
        count={isNaN(serviceRequestCounts.completedRequests)?0:serviceRequestCounts.completedRequests}
      />
      <RequestCountCard
        title={"Total Requests"}
        count={isNaN(serviceRequestCounts.totalRequests -
          serviceRequestCounts.closedRequests)?0:serviceRequestCounts.totalRequests -
          serviceRequestCounts.closedRequests
          
        }
      />
      <h5 className="ms-2">Product Requests</h5>
      <RequestCountCard
        title={"New Requests"}
        count={
          productRequestCounts.pendingRequests
            ? productRequestCounts.pendingRequests
            : "0"
        }
      />

      <RequestCountCard
        title={"Ordered Requests"}
        count={
          productRequestCounts.assignedRequests
            ? productRequestCounts.assignedRequests
            : "0"
        }
      />
      <RequestCountCard
        title={"Inprogress Requests"}
        count={isNaN(productRequestCounts.inprogressRequests)?0:productRequestCounts.inprogressRequests}
      />
      <RequestCountCard
        title={"Completed Requests"}
        count={isNaN(productRequestCounts.completedRequests)?0:productRequestCounts.completedRequests}
      />
      <RequestCountCard
        title={"Total Requests"}
        count={isNaN(productRequestCounts.totalRequests -
          productRequestCounts.closedRequests) ?0: productRequestCounts.totalRequests -
          productRequestCounts.closedRequests
          
        }
      />
    </div>
  );
}
