import { useState } from "react";
import { useParams } from "react-router-dom";
import { saveService } from "../../service/ServiceService";
import { getUploadApiUrl } from "../../service/ApiUrls";
import { upload } from "../../service/Service";

export default function useServiceForm(id, onSave, formData) {
  const { tenantId, locationId, categoryId } = useParams();

  const newData = {
    name: "",
    description: "",
    imageUrl: undefined,
    deleted: false,
    tat: "00:00",
    tenantId,
    locationId,
    category: { id: categoryId },
  };

  const [data, setData] = useState(
    formData !== undefined ? { ...formData } : { ...newData }
  );

  const [files, setFiles] = useState([]);

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [apiInProgress, setApiInProgress] = useState(false);

  const [check, setCheck] = useState(" 00hr : 00min");

  const handleInputChange = (event) => {
    console.log(event.target.name);
    if (event.target.name === "time") {
      console.log("max");
      let set = convertTimeToHoursMinutes(event.target.value);
      setCheck(set);
    }
    event.stopPropagation();
    if (event.target.name === "category") {
      setData((state) => ({
        ...state,
        category: { id: event.target.value },
      }));
      return;
    }
    setData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };
  function convertTimeToHoursMinutes(timeString) {
    // var timeParts = timeString.split(":");
    // var hours = parseInt(timeParts[0]);
    // var minutes = parseInt(timeParts[1]);
    var hours = Math.floor(timeString / 60);
    var remainingMinutes = timeString % 60;
    setData((prev) => ({
      ...prev,
      tat: `${hours}:${remainingMinutes}`,
    }));
    return " " + hours + "hr :" + ("0" + remainingMinutes).slice(-2) + "min";
  }

  const onFileSelect = (files) => {
    setFiles(files);
  };

  const handleSubmit = async (saveAndAddAnother) => {
    setApiInProgress(true);
    if (files && files.length > 0) {
      data.imageUrl = await uploadFile();
    }
    await saveService(tenantId, locationId, data);
    setApiInProgress(false);
    if (onSave) {
      onSave(data, saveAndAddAnother);
    }
    setData({ ...newData });
  };

  const uploadFile = async () => {
    setUploadInProgress(true);
    let file = files[0].file;
    const data = {
      tenantId,
      locationId,
      userId: localStorage.getItem("userId"),
      file: file,
    };
    let response = await upload(getUploadApiUrl(tenantId), data);
    setUploadInProgress(false);
    if (response) {
      return response.mediaUrl;
    }
  };

  return {
    handleInputChange,
    handleSubmit,
    onFileSelect,
    data,
    uploadInProgress,
    apiInProgress,
    check,
  };
}
