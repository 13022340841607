import React from "react";
import FormPopup from "./form-popup";
import useRoomForm from "../../hooks/forms/useRoomForm";
import FileSelector from "../inputs/file-selector";
import ProcessLoader from "../general/process-loader";
import Select from "../inputs/select";
import { nameField, descriptionField } from "../../utils/inputValidations";
import { Input } from "../inputs/input";
import { useForm, FormProvider } from "react-hook-form";

export default function RoomFormPopup({
    id,
    title,
    onSave,
    onCancel,
    formData,
    roomTypes,
    placeHolder,
}) {
    const {
        handleInputChange,
        handleSubmit,
        onFileSelect,
        data,
        uploadInProgress,
        apiInProgress,
    } = useRoomForm(onSave, formData);
    const nameInputField = nameField(
        "name",
        "name",
        placeHolder + " Name",
        "Enter room name/number",
        true,
        1,
        100
    );
    const descriptionInputField = descriptionField(
        "description",
        "description",
        placeHolder + " Description",
        "Enter " + placeHolder.toLowerCase() + " description",
        false,
        255
    );

    const methods = useForm({
        defaultValues: formData
            ? {
                name: formData.name,
                description: formData.description,
                roomType: formData.roomType,
            }
            : {},
    });

    const onSubmit = methods.handleSubmit((data) => {
        handleSubmit(data);
    });
    return (
        <FormPopup id={id} title={title} onSave={handleSubmit} onCancel={onCancel}>
            <FormProvider {...methods}>
                <form
                    onSubmit={(e) => e.preventDefault()}
                    onChange={(event) => handleInputChange(event)}
                    noValidate
                    autoComplete="off"
                    className="room-from-popup-height"
                >
                    <Select
                        id="roomType"
                        className="mb-3 col-md-12"
                        name="roomtype"
                        label="Select Room Type"
                        list={roomTypes}
                        valueProperty="id"
                    />
                    <Input id="name" {...nameInputField} className="mb-3" />
                    <Input id="description" {...descriptionInputField} className="mb-3" />
                    <FileSelector
                        name="attachment"
                        existingFiles={data.files}
                        onFileSelect={onFileSelect}
                        label="Upload icon"
                        showProgress={uploadInProgress}
                    />
                    <div className="form-footer mt-3">
                        <button
                            className={`edc-btn-secondary mb-3 col-md-12 mt-3`}
                            onClick={onSubmit}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="mb-3 col-md-12 btn btn-outline-secondary"
                            onClick={() => onCancel()}
                        >
                            Cancel
                        </button>
                    </div>
                    {apiInProgress && <ProcessLoader />}
                </form>
            </FormProvider>
        </FormPopup>
    );
}
