import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { MdError } from "react-icons/md";
import { findInputError, isFormInvalid } from "../../utils";
import { useFormContext } from "react-hook-form";
export default function Select({
  id,
  className,
  enabled = true,
  label,
  name,
  type,
  placeHolder,
  list,
  valueProperty,
  value,
  labelProperty = "name",
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);
  return (
    <div className={className ? className : ""}>
      <div className="flex justify-between">
        <label htmlFor={id} className="font-semibold capitalize">
          {label}
        </label>
        <AnimatePresence mode="wait" initial={false}>
          {isInvalid && <InputError message="Required" key="Required" />}
        </AnimatePresence>
      </div>
      <select
        defaultValue={value}
        {...register(name, { required: true })}
        className="form-control"
        disabled={!enabled}
        name={name}
        type={type}
        placeholder={placeHolder}
      >
        <option value="">Select</option>
        {list &&
          list.map((item) => (
            <option key={item[valueProperty]} value={item[valueProperty]}>
              {item[labelProperty]}
            </option>
          ))}
      </select>
    </div>
  );
}

const InputError = ({ message }) => {
  return (
    <motion.p className="error-message" {...framer_error}>
      <MdError />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
