import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllServiceRequestCount } from "../service/ServiceRequestService";
import { getAllProductRequestCount } from "../service/ProductRequestService";

export default function useRequestCount() {
  const { tenantId, locationId } = useParams();
  const [serviceRequestCounts, setServiceRequestCounts] = useState({
    pendingRequests: "",
    inprogressRequests: "",
    completedRequests: "",
    totalRequests: "",
  });
  const [productRequestCounts, setProductRequestCounts] = useState({
    pendingRequests: "",
    inprogressRequests: "",
    completedRequests: "",
    totalRequests: "",
  });
  const getAllServiceRequestCounts = async () => {
    const response = await getAllServiceRequestCount(tenantId, locationId);
    setServiceRequestCounts((prev) => ({
      ...prev,
      pendingRequests: response.newCount,
      assignedRequests: response.assignedCount,
      inprogressRequests: response.inProgressCount,
      completedRequests: response.completedCount,
      closedRequests: response.closedCount,
      totalRequests: response.totalCount,
    }));
  };
  const getAllProductRequestCounts = async() =>{
    const response = await getAllProductRequestCount(tenantId, locationId);
    setProductRequestCounts((prev) => ({
      ...prev,
      pendingRequests: response.newCount,
      assignedRequests: response.assignedCount,
      inprogressRequests: response.inProgressCount,
      completedRequests: response.completedCount,
      closedRequests: response.closedCount,
      totalRequests: response.totalCount,
    }));

  }
  useEffect(() => {
    getAllServiceRequestCounts();
    getAllProductRequestCounts();
  }, []);
  return {
    serviceRequestCounts,
    productRequestCounts
  };
}
