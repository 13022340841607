import { 
    getServicesApiUrl,
    getSaveServiceApiUrl,
    getDeleteServiceApiUrl,
    getProductRequests
 } from "./ApiUrls";
import { post, get, put, Delete } from "./Service";


export const getServices = async (tenantId, locationId) => {
    const response = await get(getServicesApiUrl(tenantId, locationId));
    if(!response) {
        return []
    }
    return response;
};

export const saveService = async (tenantId, locationId, data) => {
    const response = await post(getSaveServiceApiUrl(tenantId, locationId), data);
    return response;
};

export const updateService = async (tenantId, locationId, data) => {
    const response = await put(getSaveServiceApiUrl(tenantId, locationId), data);
    return response;
};

export const deleteService = async (tenantId, locationId, id) => {
    const response = await Delete(getDeleteServiceApiUrl(tenantId, locationId, id));
    return response;
};

export const productRequests = async(tenantId,locationId) =>{
    const response =  await get(getProductRequests(tenantId,locationId))
    return response;
}