import { getAllProductRequestCountApi, getProductRequestAssignApiUrl, getProductRequestDetailApiUrl, getProductRequestStatus, getProductRequests,  getUpdateProductRequestStatusApiUrl, productPagination } from "./ApiUrls";
  import { get, put } from "./Service";
  
 
 
  export const getProductRequestStatuses = async (tenantId, locationId) => {
    const response = await get(
      getProductRequestStatus(tenantId, locationId)
    );
    return response;
  };
  
  export const assign = async (tenantId, locationId, data) => {
    const response = await put(
      getProductRequestAssignApiUrl(tenantId, locationId),
      data
    );
    return response;
  };
  
  
 
  export const getProductRequestDetail = async (tenantId, locationId, id) => {
    const response = await get(
      getProductRequestDetailApiUrl(tenantId, locationId, id)
    );
    return response;
  };
  export const getProductRequsts = async (tenantId, locationId) => {
    const response = await get(
      getProductRequests(tenantId, locationId)
    );
    return response;
  };
  
  export const updateStatus = async (tenantId, locationId, data) => {
    const response = await put(
      getUpdateProductRequestStatusApiUrl(tenantId, locationId),
      data
    );
    return response;
  };

  export const getProductPagination = async (tenantId, locationId,currentPage,size,statusId) => {
    const response = await get(
      productPagination(tenantId, locationId,currentPage,size,statusId)
    );

    
    return response; 
  };

  export const getAllProductRequestCount = async (tenantId, locationId, id) => {
    const response = await get(
      getAllProductRequestCountApi(tenantId, locationId, id)
    );
    return response;
  };