import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getProducts, deleteProduct } from "../service/ProductService";
import { getCategoriesByType } from "../service/CategoryService";
import { getDepartments } from "../service/DepartmentService";

export default function useProducts() {
  const [list, setList] = useState([]);

  const [edit, setEdit] = useState(false);

  const { tenantId, locationId, categoryId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    coverImage: undefined,
    deleted: false,
    tenantId,
    locationId,
    turnaroundTime: "",
    stockableSr: "N",
    productQuantity: 0,
    category: { id: categoryId },
  });

  const [loading, setLoading] = useState(true);

  const [showForm, setShowForm] = useState(false);

  const [categories, setCategories] = useState([]);

  const [departments, setDepartments] = useState([]);

  const [selectedItem, setSelectedItem] = useState();

  const [showMessage, setShowMessage] = useState(false);

  const [message, setMessage] = useState("");

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = async (response) => {
    
    setShowForm(false);
    fetchAll();
      displayMessage(
        `Product '${response.name}' has been ${
          edit ? "updated" : "saved"
        } successfully.`
      );
    
    
    setEdit(false);
  };

  const fetchAll = async () => {
    const response = await getProducts(tenantId, locationId);
    setList(response);
    setLoading(false);
  };

  const fetchCategories = async () => {
    const response = await getCategoriesByType(tenantId, locationId, "PRODUCT");
    if(response){
      const categories = response.filter((item) => item.deleted === false);
      setCategories(categories);
    }
    
  };

  const fetchDepartments = async () => {
    const response = await getDepartments(tenantId, locationId);
    const departments = response.filter((item) => item.deleted === false);
    setDepartments(departments);
  };

  const handleAddClick = () => {
    setEdit(false);
    setFormData({
      category: {},
      department: {},
      name: "",
      description: "",
      locationId: locationId,
      wasPrice: "",
      turnaroundTime:0,
      stockableSr:false,
      productQuantity:0
    });
    setShowForm(true);
  };

  const handleCancelClick = () => {
    setShowForm(false);
  };

  const handleEditClick = (event, row) => {
    event.stopPropagation();
    if (!row.category) {
      row.category = {};
    }
    if (!row.department) {
      row.department = {};
    }
    if (row.coverImage) {
      row.files = [{ image: row.coverImage, name: "GJ Concierge" }];
    } else {
      row.files = [];
    }

    setFormData(row);
    setShowForm(true);
  };

  const handleDeleteClick = (event, row) => {
    event.stopPropagation();
    setSelectedItem(row);
    setShowConfirmation(true);
  };

  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const onDeleteCancel = () => {
    setShowConfirmation(false);
  };

  const onDeleteConfirm = async () => {
    await deleteProduct(tenantId, locationId, selectedItem.id);
    fetchAll();
    setShowConfirmation(false);
    displayMessage(
      `Product '${selectedItem.name}' has been deleted successfully.`
    );
  };

  useEffect(() => {
    fetchAll();
    fetchCategories();
    fetchDepartments();
  }, [locationId]);

  return {
    showForm,
    list,
    formData,
    handleSubmit,
    loading,
    handleAddClick,
    handleDeleteClick,
    handleCancelClick,
    categories,
    departments,
    handleEditClick,
    showMessage,
    showConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    message,
    selectedItem,
    setEdit,
    setFormData,
    
  };
}
