import React from "react";
import FormPopup from "./form-popup";
import useCategoryForm from "../../hooks/forms/useCategoryForm";
import FileSelector from "../inputs/file-selector";
import ProcessLoader from "../general/process-loader";
import Select from "../inputs/select";
import { nameField, descriptionField } from "../../utils/inputValidations";
import { Input } from "../inputs/input";
import { useForm, FormProvider } from "react-hook-form";

export default function CategoryFormPopup({
  id,
  title,
  onSave,
  onCancel,
  formData,
  types,
  placeHolder,
}) {
  const {
    handleInputChange,
    handleSubmit,
    onFileSelect,
    uploadInProgress,
    apiInProgress,
  } = useCategoryForm(onSave);
  const nameInputField = nameField(
    "name",
    "name",
    placeHolder + " Name",
    "Enter " + placeHolder.toLowerCase() + " name",
    true,
    3,
    100
  );
  const descriptionInputField = descriptionField(
    "description",
    "description",
    placeHolder + " Description",
    "Enter " + placeHolder.toLowerCase() + " description",
    false,
    255
  );

  const methods = useForm({
    defaultValues: formData
      ? {
          id: formData.id,
          name: formData.name,
          description: formData.description,
          type: formData.type,
          imageUrl: formData.imageUrl,
        }
      : {},
  });

  const onSubmit = methods.handleSubmit((data) => {
    handleSubmit(data);
  });
  return (
    <FormPopup id={id} title={title} onSave={handleSubmit} onCancel={onCancel}>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
        >
          <Select
            id="type"
            className="mb-3 col-md-12"
            value={formData.type}
            name="type"
            label="Select Category Type"
            list={types}
            valueProperty="id"
            onChange={handleInputChange}
          />
          <Input id="name" {...nameInputField} className="mb-3" />
          <Input id="description" {...descriptionInputField} className="mb-3" />
          <FileSelector
            name="attachment"
            existingFiles={formData.files}
            onFileSelect={onFileSelect}
            label="Upload icon"
            showProgress={uploadInProgress}
          />
          <div className="form-footer mt-3">
            <button
              className={`edc-btn-secondary mb-3 col-md-12 mt-3`}
              onClick={onSubmit}
            >
              Save
            </button>
            <button
              type="button"
              className="mb-3 col-md-12 btn btn-outline-secondary"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
          </div>
          {apiInProgress && <ProcessLoader />}
        </form>
      </FormProvider>
    </FormPopup>
  );
}
