import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getFloor } from "../service/FloorService"
import { deleteRoom, getRoomTypes } from "../service/RoomService"

import { getRoomDetailUrl } from "../utils/Constant"

export default function useFloorDetail() {

    const [floor, setFloor] = useState([])

    const [formData, setFormData] = useState(undefined)

    const [loading, setLoading] = useState(true)

    const { tenantId, locationId, floorId } = useParams()

    const [showForm, setShowForm] = useState(false)

    const navigate = useNavigate()

    const [selectedItem, setSelectedItem] = useState()

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const [roomTypes, setRoomTypes] = useState([])


    const handleSubmit = async (response) => {
        setShowForm(false)
        fetchFloor()
        displayMessage(`Room '${response.name}' has been saved successfully.`)

    }

    const fetchFloor = async () => {
        const response = await getFloor(tenantId, locationId, floorId)
        setFloor(response)
        setLoading(false)
    }

    const fetchRoomTypes = async () => {
        const response = await getRoomTypes(tenantId, locationId)
        if (response) {
            setRoomTypes(response)
        }
    }

    const handleAddClick = () => {
        setFormData({ roomType: {}, name: "", description: "", floorId })
        setShowForm(true)
    }

    const handleRowClick = (data) => {
        navigate(getRoomDetailUrl(tenantId, locationId, data.id, data.name))
    }

    const handleCancelClick = () => {
        setShowForm(false)
    }

    const handleEditClick = (event, row) => {
        event.stopPropagation()
        if (row.imageUrl) {
            row.files = [{ image: row.imageUrl, name: "GJ Concierge" }]
        } else {
            row.files = []
        }
        setFormData(row)
        setShowForm(true)
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
        }, 2000);
        return () => clearTimeout(timer);
    }

    const onDeleteCancel = () => {
        setShowConfirmation(false)
    }

    const onDeleteConfirm = async () => {
        await deleteRoom(tenantId, locationId, floorId, selectedItem.id)
        fetchFloor()
        setShowConfirmation(false)
        displayMessage(`Room '${selectedItem.name}' has been deleted successfully.`)
    }

    useEffect(() => {
        fetchFloor()
        fetchRoomTypes()
    }, [locationId])

    return {
        showForm, formData, handleSubmit, floor, loading, handleAddClick,
        handleRowClick,
        handleEditClick,
        handleDeleteClick,
        handleCancelClick,
        showMessage,
        showConfirmation,
        onDeleteConfirm,
        onDeleteCancel,
        message,
        selectedItem,
        roomTypes
    }

}