export const name_validation = {
  name: "name",
  label: "name",
  type: "text",
  id: "name",
  placeholder: "Enter name",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    maxLength: {
      value: 100,
      message: "100 characters max",
    },
  },
};

export const nameField = (
  id,
  name = "name",
  label = "Name",
  placeHolder = "Enter the name",
  required = false,
  min = 1,
  max = 250
) => {
  return {
    name: name,
    label: label,
    type: "text",
    id: id,
    placeholder: placeHolder,
    validation: {
      required: {
        value: required,
        message: "Required",
      },
      minLength: {
        value: min,
        message: "Minimum " + min + " characters",
      },
      maxLength: {
        value: max,
        message: max + " characters max",
      },
    },
  };
};

export const numberField = (
  id,
  name = "price",
  label = "Price",
  placeHolder = "Enter the price",
  required = false
) => {
  return {
    name: name,
    label: label,
    type: "number",
    id: id,
    placeholder: placeHolder,
    validation: {
      required: {
        value: required,
        message: "Required",
      },
    },
  };
};

export const descriptionField = (
  id,
  name = "description",
  label = "Description",
  placeHolder = "Enter the description",
  required = false,
  max = 250
) => {
  return {
    name: name,
    label: label,
    multiline: true,
    id: id,
    placeholder: placeHolder,
    validation: {
      required: {
        value: required,
        message: "Required",
      },
      maxLength: {
        value: max,
        message: max + " characters max",
      },
    },
  };
};

export const password_validation = {
  name: "token",
  label: "Password",
  type: "password",
  id: "password",
  placeholder: "Enter password",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    minLength: {
      value: 8,
      message: "Min 8 characters",
    },
  },
};
export const password_validation_2 = {
  name: "password",
  label: "Password",
  type: "password",
  id: "password",
  placeholder: "Enter password",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    minLength: {
      value: 8,
      message: "Min 8 characters",
    },
  },
};
export const num_validation = {
  name: "num",
  label: "Number",
  type: "number",
  id: "num",
  placeholder: "write a random number",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
  },
};

export const email_validation = {
  name: "email",
  label: "Email",
  type: "email",
  id: "email",
  placeholder: "Enter email address",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    pattern: {
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "Invalid Email",
    },
  },
};
export const phone_validation = {
  name: "phone",
  label: "Phone Number",
  type: "number",
  id: "phone",
  placeholder: "Enter phone number",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    minLength: {
      value: 10,
      message: "Must have 10 characters",
    },
    maxLength: {
      value: 10,
      message: "Must have 10 characters",
    },
  },
};

// export const time_validation = {
//   name: 'time',
//   label: 'Estimated Time',
//   type: 'time',
//   id: 'time',
//   placeholder: 'hh:mm',
//   validation: {
//     required: {
//       value: true,
//       message: 'Required',
//     },
//   },
// }
export const time_validation = {
  name: "time",
  label: "Estimated Time ",
  type: "text",
  id: "time",
  placeholder: "Enter in minutes",
  // pattern: "^[0-9]+$",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "Invalid time format (only numbers allowed)",
    },
  },
};
// export const time_validation = {
//   name: "time",
//   label: "Estimated Time",
//   type: "time", // Setting the input type to 'time'
//   step: 3600,
//   id: "time",
//   defaultValue: "00:00", // Setting a default value
//   placeholder: "hh:mm", // Adding placeholder for the time input
//   validation: {
//     required: {
//       value: true,
//       message: "Required",
//     },
//     pattern: {
//       value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
//       message: "Invalid time format (hh:mm)",
//     },
//   },
// };
