import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getStaffs, deleteStaff } from "../service/StaffService";
import { getDepartments } from "../service/DepartmentService";
import { getStaffDetailUrl } from "../utils/Constant";

export default function useStaffs() {
  const [list, setList] = useState([]);

  const [edit, setEdit] = useState(false);

  const [formData, setFormData] = useState(undefined);

  const [loading, setLoading] = useState(true);

  const { tenantId, locationId } = useParams();

  const [showForm, setShowForm] = useState(false);

  const [departments, setDepartments] = useState([]);

  const [selectedItem, setSelectedItem] = useState();

  const [showMessage, setShowMessage] = useState(false);

  const [message, setMessage] = useState("");

  const [showConfirmation, setShowConfirmation] = useState(false);

  const navigate = useNavigate();

  const getHeaders = () => {
    const headers = [
      { title: "Name", type: "label", property: "name" },
      { title: "Department", type: "label", property: "department" },
      { title: "Designation", type: "label", property: "designation" },
      { title: "Action", type: "action" },
    ];
    return headers;
  };

  const handleSubmit = async (selectedItem) => {
    fetchAll();
    setShowForm(false);
    displayMessage(
      `Staff '${selectedItem.user.firstName} ${selectedItem.user.lastName
      }' has been ${edit ? "updated" : "saved"} successfully.`
    );
    setEdit(false);
  };

  const handleRowClick = (data) => {
    if (data.deleted) {
      return;
    }
    navigate(getStaffDetailUrl(tenantId, locationId, data.id));
  };

  const fetchAll = async () => {
    setLoading(true);
    const response = await getStaffs(tenantId, locationId);
    setList(response);
    setLoading(false);
  };

  const fetchDepartments = async () => {
    const response = await getDepartments(tenantId, locationId);
    setDepartments(response);
  };

  const getFormData = (data) => {
    if (data && data.id) {
      return {
        imageUrl: data.user.imageUrl,
        userId: data.user.id,
        id: data.id,
        department: data.department.id,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        email: data.user.email,
        phoneNumber: data.user.phoneNumber,
      };
    }
    return {
      firstName: "",
      lastName: "",
      token: "",
      email: "",
      phoneNumber: "",
      department: "",
    };
  };

  const handleAddClick = () => {
    setFormData(getFormData());
    setShowForm(true);
  };

  const handleEditClick = (event, row) => {
    event.stopPropagation();
    let formData = getFormData(row);
    if (row.user.imageUrl) {
      formData.files = [{ image: row.user.imageUrl, name: "GJ Concierge" }];
    } else {
      formData.files = [];
    }
    setFormData(formData);
    setShowForm(true);
  };

  const handleDeleteClick = (event, row) => {
    event.stopPropagation();
    setSelectedItem(row);
    setShowConfirmation(true);
  };

  const handleCancelClick = () => {
    setShowForm(false);
  };

  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const onDeleteCancel = () => {
    setShowConfirmation(false);
  };

  const onDeleteConfirm = async () => {
    await deleteStaff(tenantId, locationId, selectedItem.id);
    fetchAll();
    setShowConfirmation(false);
    displayMessage(
      `Staff '${selectedItem.user.firstName} ${selectedItem.user.lastName}' has been deleted successfully.`
    );
  };

  useEffect(() => {
    fetchAll();
    fetchDepartments();
  }, [locationId]);

  return {
    showForm,
    list,
    formData,
    getHeaders,
    handleSubmit,
    loading,
    handleAddClick,
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
    departments,
    handleRowClick,
    showMessage,
    showConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    message,
    selectedItem,
    setEdit,
  };
}
