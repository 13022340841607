import React from 'react'
import VDiv from '../containers/vdiv'
import HDiv from '../containers/hdiv'

export default function TabBar({className, items, onClick, selectedIndex = 0}) {

    const navigateTo = (index) => {
        onClick(index)
    }

    return (
        <HDiv className={`flex tab-bar ${className ? className : ''}`}>
            {items.map((item, index) => 
            <VDiv  key={"tab"+index} className="tab" onClick={() => navigateTo(index)}>
                <span className={selectedIndex === index ? "selected" : ""}>{item}</span>
            </VDiv>
            )}
        </HDiv>
    )
}