import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getBlocks, deleteBlock } from "../service/BlockService";
import { getBlockDetailUrl } from "../utils/Constant";

export default function useBlocks() {
  const [list, setList] = useState([]);

  const [formData, setFormData] = useState(undefined);

  const [loading, setLoading] = useState(true);

  const { tenantId, locationId } = useParams();

  const [showForm, setShowForm] = useState(false);

  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState();

  const [showMessage, setShowMessage] = useState(false);

  const [message, setMessage] = useState("");

  const [edit, setEdit] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const getHeaders = () => {
    const headers = [
      { title: "Name", type: "label", property: "name" },
      { title: "Description", type: "label", property: "description" },
      { title: "Action", type: "action" },
    ];
    return headers;
  };

  const handleSubmit = async (response) => {
    setShowForm(false);
    fetchAll();
    displayMessage(
      `Block '${response.name}' has been ${edit ? "updated" : "saved"
      } successfully.`
    );
    setEdit(false);
  };

  const fetchAll = async () => {
    const response = await getBlocks(tenantId, locationId);
    setList(response);
    setLoading(false);
  };

  const handleRowClick = (data) => {
    if (data.deleted) {
      return;
    }
    navigate(getBlockDetailUrl(tenantId, locationId, data.id, data.name));
  };

  const handleAddClick = () => {
    setEdit(false);
    setFormData(undefined);
    setShowForm(true);
  };

  const handleCancelClick = () => {
    setShowForm(false);
  };

  const handleEditClick = (event, row) => {
    event.stopPropagation();
    if (row.imageUrl) {
      row.files = [{ image: row.imageUrl, name: "GJ Concierge" }];
    } else {
      row.files = [];
    }
    setFormData(row);
    setShowForm(true);
  };

  const handleDeleteClick = (event, row) => {
    event.stopPropagation();
    setSelectedItem(row);
    setShowConfirmation(true);
  };

  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const onDeleteCancel = () => {
    setShowConfirmation(false);
  };

  const onDeleteConfirm = async () => {
    await deleteBlock(tenantId, locationId, selectedItem.id);
    fetchAll();
    setShowConfirmation(false);
    displayMessage(
      `Block '${selectedItem.name}' has been deleted successfully.`
    );
  };

  useEffect(() => {
    fetchAll();
  }, [locationId]);

  return {
    showForm,
    list,
    formData,
    getHeaders,
    handleSubmit,
    loading,
    handleAddClick,
    handleRowClick,
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
    showMessage,
    showConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    message,
    selectedItem,
    setEdit,
    edit,
  };
}
