import React from 'react'

export default function Card({className, children, style, onClick, title, subTitle, onAdd, buttonLabel = "Add", showAddButton = true}) {
    return (
        <div className={`card ${className ? className : ''}`} style={style ? style : {}} onClick={onClick}>
            {title &&
                <div className="card-header pb-0 p-3">
                    <div className="row">
                        <div className="col-md-8 align-items-center">
                            <h6 className="mb-0">{title}</h6>
                            {subTitle && <p>{subTitle}</p> }
                        </div>
                        {showAddButton &&
                            <div className="col-md-4 text-end">
                                <button type="button" className="btn btn-primary pull-right" onClick={() => onAdd()}>{buttonLabel}</button>
                            </div>
                        }
                    </div>
                </div>
            }
            <div className='card-body'>{children}</div>
        </div>
    )
}