import React, { useState } from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import Layout from "../components/layout/layout";
import useDepartmentDetail from "../hooks/useDepartmentDetail";
import DataTable from "react-data-table-component";
import NoDataMessage from "../components/general/NoDataMessage";
import useDepartmentForm from "../hooks/forms/useDepartmentForm";
import DepartmentUserPopup from "../components/popups/department-user-form-popup";
import Toast from "../components/general/toast";
import HDiv from "../components/containers/hdiv";
import Avatar from "../components/general/avatar";
import { DEPARTMENT_ADMIN_AUTO_ASSIGN } from "../service/ApiUrls";
import { get, put } from "../service/Service";
import { useEffect } from "react";

export default function DepartmentDetail() {
  const {
    services,
    toggleSelection,
    loading,
    onSaveServices,
    name,
    handleClickDepartmentSection,
    enable,
    list,
    handleRowClick,
    handleAddDepartmentUser,
    deptUser,
    departmentId,
    handleSubmit,
    showMessage,
    message,
    handleCancelClick,
    deptProducts
  } = useDepartmentDetail();

  const columns = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}
        >
          {" "}
          {index + 1}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Name",
      id: "tableHeader",
      selector: (row) => row.name,
      sortable: false,
      cell: (row) => (
        <span
          className={`avatar-container capitalize ${row.deleted ? "text-danger" : ""
            }`}
          onClick={() => handleRowClick(row)}

        >
          <Avatar image={row.imageUrl} name={row.firstName} />{" "}
          <strong>{row.firstName + " " + row.lastName}</strong>
        </span>
      ),
    },
    // {
    //     name: 'Last Name',
    //     cell: row => <span > {row.lastName}</span>,
    //     sortable: true,
    // },
    {
      name: "Email",
      id: "tableHeader",
      cell: (row) => <span onClick={() => handleRowClick(row)}
      > {row.email}</span>,
      // center: "false",
    },
    {
      name: "Phone Number",
      id: "tableHeader",
      cell: (row) => <span onClick={() => handleRowClick(row)}
      > {row.phoneNumber}</span>,
      right: "true",
    },
    {
      name: "Active",
      id: "tableHeader",
      cell: (row) => (
        <span onClick={() => handleRowClick(row)}
        > {row.active === true ? "Active" : "Inactive"}</span>
      ),
      center: "true",
    },
  ];

  const [isChecked, setIsChecked] = useState(false);
  // eslint-disable-next-line
  const [isDepAdminAssign, setIsDepAdminAssign] = useState({});

  const handleChange = async () => {
    setIsChecked(!isChecked);
    isDepAdminAssign.autoAssign = !isChecked;
    isDepAdminAssign.departmentId = localStorage.getItem("departmentId");
    await put(DEPARTMENT_ADMIN_AUTO_ASSIGN, isDepAdminAssign);
  };
  const handleDepartmentAdminServices = async () => {
    const response = await get(
      `${DEPARTMENT_ADMIN_AUTO_ASSIGN}/${localStorage.getItem("departmentId")}`
    );
    setIsChecked(response.autoAssign);
  };
  useEffect(() => {
    handleDepartmentAdminServices();
  }, [localStorage.getItem("departmentId")]);


  const data = [
    {id : '1',
     tabTitle: "Available Services",
     tabContent:<div className="d-flex"> <div className="w-100 row">
      <div className="col col-sm-6 list"> 
      <div className="row">
     {services &&
       services.length > 0 &&
       services.map((data) =>
         data.deleted ? (
           <div
             className="col-sm-12"
             key={data.service.id}
             onClick={() => toggleSelection(data, false)}
           >
             <span className="list-item">
               {data.service.name}
             </span>
           </div>
         ) : null
       )}
       </div>
       </div>
  
   <div className="col col-sm-6 list">
   <h5>Selected Department Services</h5>
   <div className="row">
     {services &&
       services.length > 0 &&
       services.map((data) =>
         !data.deleted ? (
           <div
             className="col-sm-12"
             key={data.service.id}
             onClick={() => toggleSelection(data, true)}
           >
             <span className="list-item">
               {data.service.name}
             </span>
           </div>
         ) : null
       )}
   </div>
 </div>
 </div>
 </div>
    },
    {id : '2',
     tabTitle: "Available Products",
     tabContent: <div className={`col col-sm-6 list`}> 
     <div className="row">
    {deptProducts &&
      deptProducts.length > 0 ?
      deptProducts.map((data) =>
        !data.deleted ? (
          <div
            className="col-sm-12"
            key={data.id}
          >
            <span className="list-item">
              {data.name}
            </span>
          </div>
        ) : null
      )
    : <div className="col-sm-12 d-flex justify-content-center">No products found</div>
    }
      </div>
      </div>
    }
  ]
  const [visibleTab, setVisibleTab] = useState(data[0].id)

  const listTitles = data.map((item) => 
  <li onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"}>{item.tabTitle}</li>
)       
const listContent = data.map((item) => 
<p style={visibleTab === item.id ? {} : {display: 'none'}}>{item.tabContent}</p>
)
  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HDiv className={`flex tab-bar mt-3 pb-3`}>
            <div className="d-flex gap-4 justify-content-start ms-4 mt-3">
              {enable ? (
                <div
                  className="shadow-none pull-right mb-0 capitalize"
                  onClick={(event) => {
                    handleClickDepartmentSection(event, "Department");
                  }}
                >
                  Department
                </div>
              ) : (
                <div
                  className="pull-right mb-0 text-primary border-bottom border-primary border-4 pb-2 fw-bold"
                  onClick={(event) => {
                    handleClickDepartmentSection(event, "Department");
                  }}
                >
                  Department
                </div>
              )}
              <HDiv className={"justify-content-between"}>
                {!enable ? (
                  <div
                    className="shadow-none pull-right mb-0"
                    onClick={(event) => {
                      handleClickDepartmentSection(event, "Staffs");
                    }}
                  >
                    Staffs
                  </div>
                ) : (
                  <div
                    className=" pull-right mb-0 border-bottom border-primary text-primary border-4 pb-2 fw-bold"
                    onClick={(event) => {
                      handleClickDepartmentSection(event, "Staffs");
                    }}
                  >
                    Staffs
                  </div>
                )
                }
              </HDiv>
            </div>
          </HDiv>
          {enable && (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary pull-right mt-2"
                onClick={handleAddDepartmentUser}
              >
                Add
              </button>
            </div>
          )}
          {enable === false ? (
            <>
              <div className="d-flex text-info text-bold">
                <div className="ms-2">
                  Service Allocation To Department Admin{" "}
                  {isChecked === true ? "Enabled" : "Disabled"}
                </div>
                <div className="form-check form-switch ms-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    checked={isChecked}
                    aria-checked={isChecked}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <Card
                title={`${name} Department`}
                subTitle={`Add/Remove '${name}' Department services`}
                buttonLabel="Save"
                showAddButton={visibleTab === data[0].id ? true:false}
                onAdd={onSaveServices}
              >
                      <div className="tabs">
                       <ul className="tabs-titles">
                         {listTitles}
                       </ul>
                       <div className="tab-content">
                         {listContent}
                       </div>
                   </div>
                
              </Card>
            </>
          ) : (
            <>
              <Card>
                {list && list.length > 0 ? (
                  <DataTable
                    data={list}
                    columns={columns}
                    pagination
                    className="dataTable"
                    onRowClicked={handleRowClick}
                  />
                ) : (
                  <NoDataMessage
                    showAddButton
                    onAdd={handleAddDepartmentUser}
                  />
                )}
              </Card>
              {deptUser && (
                <DepartmentUserPopup
                  placeHolder="Department"
                  hook={useDepartmentForm}
                  departmentId={departmentId}
                  onSave={handleSubmit}
                  onCancel={handleCancelClick}
                  title="Department User"
                />
              )}
            </>
          )}
        </>
      )}
      {showMessage && <Toast text={message} className="success" />}
    </Layout>
  );
}
