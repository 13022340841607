import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import NoDataMessage from "../components/general/NoDataMessage";
import Layout from "../components/layout/layout";
import RoomFormPopup from "../components/popups/room-form-popup";
import useFloorDetail from "../hooks/useFloorDetail";
import Popover from "../components/popups/popover";
import Toast from "../components/general/toast";
import Avatar from "../components/general/avatar";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";
import DataTable from "react-data-table-component";

export default function FloorDetail() {

    const { showForm, formData, handleSubmit, floor, loading, handleAddClick, handleRowClick, handleCancelClick,
        handleDeleteClick,
        handleEditClick,
        showMessage,
        showConfirmation,
        onDeleteConfirm,
        onDeleteCancel,
        message, selectedItem,
        roomTypes
    } = useFloorDetail()

    const columns = [
        {
            name: 'S. No',
            cell: (row, index) => <span className={`${row.deleted ? 'text-danger' : ''}`} onClick={() => handleRowClick(row)}> {index + 1}</span>,
            width: "80px",
            center: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: false,
            cell: row => <span className={`avatar-container capitalize ${row.deleted ? 'text-danger' : ''}`} onClick={() => handleRowClick(row)}>
                <Avatar image={row.imageUrl} name={row.name} />
                <span>
                    <strong>{row.name}</strong>
                    <p className="description">{row.description}</p>
                </span>
            </span>,
            width: "400px"
        },
        {
            name: 'Room Type',
            selector: row => <span className={`${row.deleted ? 'text-danger' : ''}`} onClick={() => handleRowClick(row)}> {row.roomType !== null ? row.roomType.name : ""}</span>,
            sortable: false,
        },
        {
            name: "Edit",
            selector: row => !row.deleted && <EditButton onClick={(event) => { handleEditClick(event, row) }} />,
            width: "80px",
            center: true
        },
        {
            name: "Delete",
            selector: row => !row.deleted && <DeleteButton onClick={(event) => handleDeleteClick(event, row)} />,
            width: "80px",
            center: true
        },
    ];

    return (
        <Layout>
            {loading ? <Loader /> :
                <>
                    <Card title={floor.name} subTitle="Add/Edit/Delete Floor Rooms" showAddButton onAdd={handleAddClick}>
                        <div className="row">
                            {floor.rooms && floor.rooms.length > 0 ?
                                <DataTable data={floor.rooms} onRowClicked={handleRowClick} className="dataTable" columns={columns} pagination />

                                : <NoDataMessage showAddButton onAdd={handleAddClick} />}
                        </div>
                    </Card>
                    {showForm && <RoomFormPopup roomTypes={roomTypes} placeHolder="Room" onCancel={handleCancelClick} onSave={handleSubmit} formData={formData} title="Room" />}
                </>
            }
            {showConfirmation &&
                <Popover
                    title={`Confirm deleting '${selectedItem.name}'`}
                    showActions
                    saveLabel="Delete"
                    onCancel={onDeleteCancel}
                    onClose={onDeleteCancel}
                    onSave={onDeleteConfirm}
                >
                    <p className="mt-1">{`Are you sure that you want to delete the room '${selectedItem.name}'?`}</p>
                </Popover>
            }

            {showMessage && <Toast text={message} className="success" />}
        </Layout>
    )
}