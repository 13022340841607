import { useState } from 'react'
import { useParams } from "react-router-dom"
import { saveRoom } from '../../service/RoomService';
import { getUploadApiUrl } from '../../service/ApiUrls'
import { upload } from '../../service/Service'

export default function useRoomForm(onSave, formData) {

    const { tenantId, locationId, floorId } = useParams();

    const newData = { name: "", description: "", imageUrl: undefined, deleted: false, tenantId, locationId, floorId, roomType: { id: undefined } }

    const [data, setData] = useState(formData !== undefined ? { ...formData } : { ...newData })

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const [apiInProgress, setApiInProgress] = useState(false)

    const handleInputChange = (event) => {
        event.stopPropagation()
        if (event.target.name === "attachment") {
            return
        }
    }

    const onFileSelect = (files) => {
        setFiles(files)
    }

    const handleSubmit = async (data) => {
        let payload = { id: formData.id, name: data.name, description: data.description, roomType: { id: data.roomtype } }
        if (files && files.length > 0) {
            payload.imageUrl = await uploadFile()
        }
        payload.floorId = {
            "id":floorId
        }
        setApiInProgress(true)
        await saveRoom(tenantId, locationId, floorId, payload)
        setApiInProgress(false)
        if (onSave) {
            onSave(data)
        }
        setData({ ...newData })
    }

    const uploadFile = async () => {
        setApiInProgress(true)
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            tenantId,
            locationId,
            userId: localStorage.getItem("userId"),
            file: file
        }
        let response = await upload(getUploadApiUrl(tenantId), data)
        setUploadInProgress(false)
        if (response) {
            return response.mediaUrl;
        }
    }

    return { handleInputChange, handleSubmit, onFileSelect, data, uploadInProgress, apiInProgress }
}