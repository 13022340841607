
import React from "react";
import ServiceRequestDetailSection from "../components/sections/service-request-detail-section";
import Layout from "../components/layout/layout";
import Card from "../components/cards/card";
import { useState } from "react";
import HDiv from "../components/containers/hdiv";

export default function ServiceRequestDetail() {
   const [enable, setEnable] = useState(false);

   const handleClickDepartmentSection = (event, section) => {
      if (section === "ServiceRequest") {
         setEnable(false);
      } else {
         setEnable(true);
      }
   };
   return (
      <Layout>
         <HDiv className={`flex tab-bar mb-3 py-2`}>
            <div className="d-flex gap-4 justify-content-start ms-4 my-2">
               <h5
                  className="shadow-none pull-right m-0 capitalize text-nowrap"
                  onClick={(event) => {
                     handleClickDepartmentSection(event, "ServiceRequest");
                  }}
               >
                  Service Request - Tracking
               </h5>
            </div>

            {/* <div className="d-flex gap-4 justify-content-start ms-4 mt-3">
               {enable ? (
                  <div
                     className="shadow-none pull-right mb-0 capitalize text-nowrap"
                     onClick={(event) => {
                        handleClickDepartmentSection(event, "ServiceRequest");
                     }}
                  >
                     Service Request
                  </div>
               ) : (
                  <div
                     className="pull-right mb-0 text-primary border-bottom border-primary border-4 pb-2 fw-bold text-nowrap"
                     onClick={(event) => {
                        handleClickDepartmentSection(event, "ServiceRequest");
                     }}
                  >
                     Service Request
                  </div>
               )}
               <HDiv className={"justify-content-between"}>
                  {!enable ? (
                     <div
                        className="shadow-none pull-right mb-0"
                        onClick={(event) => {
                           handleClickDepartmentSection(event, "Details");
                        }}
                     >
                        Details
                     </div>
                  ) : (
                     <div
                        className=" pull-right mb-0 border-bottom border-primary text-primary border-4 pb-2 fw-bold"
                        onClick={(event) => {
                           handleClickDepartmentSection(event, "Details");
                        }}
                     >
                        Details
                     </div>
                  )
                  }
               </HDiv>
            </div> */}
         </HDiv>
         <Card showAddButton={false}>
            <ServiceRequestDetailSection enable={enable} />
         </Card>

      </Layout>
   );
}