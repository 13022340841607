import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import { saveRoomServices, getRoomServices } from "../service/RoomService"


export default function useRoomDetail() {

    const [services, setServices] = useState([])

    const { tenantId, locationId, roomId, name } = useParams();

    const [loading, setLoading] = useState(true)

    const [selectedTab, setSelectedTab] = useState(0)

    const qrCode = "https://dev-user-concierge.gjglobalsoft.com/#/t/" + tenantId + "/r/" + roomId

    const onSaveServices = async () => {
        setLoading(true)
        await saveRoomServices(tenantId, locationId, roomId, services)
        setLoading(false)
    }

    const fetchServices = async () => {
        const response = await getRoomServices(tenantId, locationId, roomId)
        let filteredReponse = response.filter((item) => item.service.deleted === false)
        setServices(filteredReponse)
        setLoading(false)
    }

    const toggleSelection = (data, deleted) => {
        data.deleted = deleted;
        setServices([...services])
    }

    const onTabClick = (index) => {
        setSelectedTab(index)
    }

    useEffect(() => {
        fetchServices()
    }, [locationId])

    return { services, loading, toggleSelection, onSaveServices, qrCode, name, selectedTab, onTabClick }

}