import { 
    getCategoriesApiUrl,
    getSaveCategoryApiUrl,
    getCategoryApiUrl,
    getDeleteCategoryApiUrl,
    getCategoriesByTypeApiUrl
 } from "./ApiUrls";
import { post, get, put, Delete } from "./Service";


export const getCategories = async (tenantId, locationId) => {
    const response = await get(getCategoriesApiUrl(tenantId, locationId));
    if(!response) {
        return []
    }
    return response;
};

export const getCategoriesByType = async (tenantId, locationId, type) => {
    const response = await get(getCategoriesByTypeApiUrl(tenantId, locationId, type));
    if(!response) {
        return []
    }
    return response;
};

export const getCategory = async (tenantId, locationId, categoryId) => {
    const response = await get(getCategoryApiUrl(tenantId, locationId, categoryId));
    if(!response) {
        return []
    }
    return response;
};



export const saveCategory = async (tenantId, locationId, data) => {
    const response = await post(getSaveCategoryApiUrl(tenantId, locationId), data);
    return response;
};

export const updateCategory = async (tenantId, locationId, data) => {
    const response = await put(getSaveCategoryApiUrl(tenantId, locationId), data);
    return response;
};

export const deleteCategory = async (tenantId, locationId, id) => {
    const response = await Delete(getDeleteCategoryApiUrl(tenantId, locationId, id));
    return response;
};