import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  createDepartmentUser,
  saveDepartment,
  updateDepartment,
} from "../../service/DepartmentService";
import {
  DEPARTMENT_ADMIN_AUTO_ASSIGN,
  getUploadApiUrl,
} from "../../service/ApiUrls";
import { post, upload } from "../../service/Service";

export default function useDepartmentForm(onSave, formData, departmentId) {
  const { tenantId, locationId } = useParams();

  const newData = {
    name: "",
    description: "",
    imageUrl: undefined,
    deleted: false,
    tenantId,
    locationId,
  };

  const [isDepAdminAssign, setIsDepAdminAssign] = useState({});

  const [data, setData] = useState(
    formData !== undefined ? { ...formData } : { ...newData }
  );

  const [files, setFiles] = useState([]);

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [apiInProgress, setApiInProgress] = useState(false);

  const [departmentData, setDepartmentData] = useState({});

  const [departmentUserData, setDepartmentUserData] = useState({});

  const handleInputChange = (event) => {
    if (event.target.name === "attachment") {
      return;
    }
    event.stopPropagation();
    setData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };
  const onFileSelect = (files) => {
    setFiles(files);
  };

  const handleSubmit = async (
    saveAndAddAnother,
    dataUpdate,
    updateDepartmentId
  ) => {
    setApiInProgress(true);
    if (departmentId && departmentId.length > 0) {
      departmentUserData.tenantId = data.tenantId;
      departmentUserData.location = {};
      departmentUserData.location.id = data.locationId;
      departmentUserData.user = {};
      departmentUserData.user.firstName = data.firstName;
      departmentUserData.user.lastName = data.lastName;
      departmentUserData.user.email = data.email;
      departmentUserData.user.token = data.token;
      departmentUserData.user.phoneNumber = data.phone;
      departmentUserData.department = {};
      departmentUserData.department.id = departmentId;
      // if (resp) {
      await createDepartmentUser(
        tenantId,
        locationId,
        departmentId,
        departmentUserData
      );
      // }
      setApiInProgress(false);
      if (onSave) {
        onSave(data, saveAndAddAnother);
      }
      setData({ ...newData });
    } else {
      if (files && files.length > 0) {
        // data.imageUrl = await uploadFile()
        departmentData.imageUrl = await uploadFile();
      }
      departmentData.name = data.name;
      departmentData.description = data.description;
      departmentData.deleted = data.deleted;
      departmentData.tenantId = data.tenantId;
      departmentData.locationId = data.locationId;
      // await saveDepartment(tenantId, locationId, data)
      let resp;
      if (dataUpdate && dataUpdate === true) {
        departmentData.id = updateDepartmentId;
        resp = await updateDepartment(tenantId, locationId, departmentData);
      } else {
        resp = await saveDepartment(tenantId, locationId, departmentData);
        departmentUserData.tenantId = data.tenantId;
        departmentUserData.location = {};
        departmentUserData.location.id = data.locationId;
        departmentUserData.user = {};
        departmentUserData.user.firstName = data.firstName;
        departmentUserData.user.lastName = data.lastName;
        departmentUserData.user.email = data.email;
        departmentUserData.user.token = data.token;
        departmentUserData.user.phoneNumber = data.phone;
        departmentUserData.department = {};
        departmentUserData.department.id = resp.id;
        const response = await createDepartmentUser(
          tenantId,
          locationId,
          resp.id,
          departmentUserData
        );
        isDepAdminAssign.tenantId = localStorage.getItem("tenantId");
        isDepAdminAssign.locationId = localStorage.getItem("locationId");
        isDepAdminAssign.autoAssign = false;
        isDepAdminAssign.departmentId = response.department.id;
        isDepAdminAssign.userId = response.user.id;
        isDepAdminAssign.role = "ROLE_ADMIN";
        isDepAdminAssign.staffId = response.id;
        await post(DEPARTMENT_ADMIN_AUTO_ASSIGN, isDepAdminAssign);
        setIsDepAdminAssign({});
      }
      setApiInProgress(false);
      if (onSave) {
        onSave(data, saveAndAddAnother);
      }
      setData({ ...newData });
    }
  };

  const uploadFile = async () => {
    setUploadInProgress(true);
    let file = files[0].file;
    const data = {
      tenantId,
      locationId,
      userId: localStorage.getItem("userId"),
      file: file,
    };
    let response = await upload(getUploadApiUrl(tenantId), data);
    setUploadInProgress(false);
    if (response) {
      return response.mediaUrl;
    }
  };

  return {
    handleInputChange,
    handleSubmit,
    onFileSelect,
    data,
    uploadInProgress,
    apiInProgress,
    setDepartmentData,
    setDepartmentUserData,
  };
}
