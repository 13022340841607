import { 
    getProductsApiUrl,
    getSaveProductApiUrl,
    getDeleteProductApiUrl,
 } from "./ApiUrls";
import { post, put, Delete, get } from "./Service";


export const getProducts = async (tenantId, locationId) => {
    const response = await get(getProductsApiUrl(tenantId, locationId));
    if(!response) {
        return []
    }
    return response;
};

export const saveProduct = async (tenantId, locationId, data) => {
    const response = await post(getSaveProductApiUrl(tenantId, locationId), data);
    return response;
};

export const updateProduct = async (tenantId, locationId, data) => {
    const response = await put(getSaveProductApiUrl(tenantId, locationId), data);
    return response;
};

export const deleteProduct = async (tenantId, locationId, id) => {
    const response = await Delete(getDeleteProductApiUrl(tenantId, locationId, id));
    return response;
};

