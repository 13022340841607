import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmPopupModal from "../popups/confirmationPopup";
// import { unsubscribeFromGroup } from "../../firebase";
import { getAllNotificationUrl } from "../../service/ApiUrls";
import axios from "axios";

export default function Header() {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      getAllNotifications();
    }, 1000);
    // eslint-disable-next-line
  }, [localStorage.getItem("trigger")]);
  const handleSideBar = () => {
    document.querySelector(".g-sidenav-show").classList.add("g-sidenav-pinned");
  };

  const getAllNotifications = async () => {
    const response = await axios.get(
      getAllNotificationUrl(localStorage.getItem("tenantId"))
    );
    setNotifications(response.data.list);
  };
  const logout = () => {
    // const firebaseToken = localStorage.getItem("notifyToken"); // Retrieve the Firebase token
    // const groupIdToUnsubscribe = "concierge-group"; // Replace with the group ID you want to unsubscribe from

    // if (firebaseToken) {
    //   unsubscribeFromGroup(firebaseToken, groupIdToUnsubscribe)
    //     .then(() => {
    //       // console.log(`Unsubscribed from group: ${groupIdToUnsubscribe}`);
    //     })
    //     .catch((error) => {
    //       console.error(
    //         `Error unsubscribing from group: ${groupIdToUnsubscribe}`,
    //         error
    //       );
    //     });
    // }
    localStorage.clear(); // Clear the Firebase token from local storage
    navigate("/");
  };
  const [businessName, setBuisnessName] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  useEffect(() => {
    setTimeout(() => {
      if (
        localStorage.getItem("BusinessName") === null ||
        localStorage.getItem("BusinessName") === "undefined"
      ) {
        setBuisnessName("");
      } else {
        setBuisnessName(localStorage.getItem("BusinessName"));
      }
      setBusinessImage(localStorage.getItem("BusinessImage"));
    }, 0.0001);
    const cardElement = document.getElementById('card1');
   if(cardElement){

    cardElement.style.backgroundImage = `url('${localStorage.getItem("BusinessImage")}')`;

   }
  }, [localStorage.getItem("BusinessName")]);


  return (
    <nav
      className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
      id="navbarBlur"
      navbar-scroll="true"
    >
      <div className="container-fluid py-1 px-3 ">
        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
          {/* <div className="input-group">
            <span className="input-group-text text-body">
              <i className="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Type here..."
            />
          </div> */}
          <div className="position-relative d-flex align-items-center justify-content-start">
            {((businessImage !== "null") && (businessImage !== "undefined")) && (
              <div className="business-logo-header">
                <div className="card-logo-shadow" id="card1"></div>
                {/* <div className="business-logo-header mat">
                   <img
                    className="position-relative w-100 pe-1"
                    src={businessImage}
                    alt="img"
                  /> 
                </div> */}
              </div>
            )}
            <p className="text-bold capitalize">{businessName}</p>
          </div>
        </div>
        <div
          className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
          id="navbar"
        >
          <div className="ms-md-auto pe-md-3 d-flex align-items-center"></div>
          <ul className="navbar-nav  justify-content-end">
            <li className="nav-item d-flex align-items-center px-3">
              <div className="nav-link text-body font-weight-bold px-0 cursor-pointer">
                <div className="dropstart">
                  <i
                    className="fa fa-user me-sm-1 image-pop-zoom-up-big"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></i>
                  <ul className="dropdown-menu">
                    <li className="ps-3 d-flex justify-between align-items-center">
                      <a
                        href={`#/t/${localStorage.getItem(
                          "tenantId"
                        )}/l/${localStorage.getItem("locationId")}`}
                      >
                        <p className="text-primary text-bold capitalize">{`${localStorage.getItem(
                          "firstName"
                        )} ${localStorage.getItem("lastName")}`}</p>
                      </a>
                    </li>
                    <li
                      data-bs-toggle="modal"
                      data-bs-target="#ConfirmModal"
                      className="ps-3 d-flex justify-between align-items-center"
                    >
                      <p>Logout</p>
                      <i className="fa fa-sign-out cursor-pointer pe-3"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
              <div
                // href="index.html"
                className="nav-link text-body p-0"
                id="iconNavbarSidenav"
                onClick={handleSideBar}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
            {/* <li className="nav-item px-3 d-flex align-items-center">
              <a href="index.html" className="nav-link text-body p-0">
              <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
              </a> 
            </li> */}
            <li className="nav-item dropdown pe-2 d-flex align-items-center">
              <div
                className="nav-link text-body p-0"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {notifications && notifications.length > 0 ? (
                  <div className="notification-icon">
                    <i className="fas fa-bell cursor-pointer image-pop-zoom-up-big"></i>
                    <div className="notification-dot"></div>
                  </div>
                ) : (
                  <i className="fa fa-bell cursor-pointer image-pop-zoom-up-big"></i>
                )}
              </div>
              <ul
                className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4 h-25vh overflow-auto"
                aria-labelledby="dropdownMenuButton"
              >
                {/* {message && message.length > 0 ? (
                  message.map((item, index) => (
                    <li key={index} className="mb-2" onClick={handleContext}>
                      <a
                        className="dropdown-item border-radius-md"
                        href={`#/t/${localStorage.getItem(
                          "tenantId"
                        )}/l/${localStorage.getItem(
                          "locationId"
                        )}/service-requests`}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto"></div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              <span className="font-weight-bold">
                                {item[0].title}
                              </span>{" "}
                            </h6>
                            <p className="text-xs text-secondary mb-0 ">
                              {item[0].body !== "null"
                                ? item[0].body.substring(0, 30) + "..."
                                : `New request for ${item[0].title} `}
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))
                ) : (
                  <div className="text-center">No Notifications</div>
                )} */}
                {notifications ? (
                  notifications.map((item, index) => (
                    <li key={index} className="mb-2">
                      <a
                        className="dropdown-item border-radius-md"
                        href={`#/t/${localStorage.getItem(
                          "tenantId"
                        )}/l/${localStorage.getItem(
                          "locationId"
                        )}/service-requests`}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto"></div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              <span className="font-weight-bold">
                                {item.subject}
                              </span>{" "}
                            </h6>
                            <p className="text-xs text-secondary mb-0 ">
                              {item.content
                                ? item.content.substring(0, 30) + "..."
                                : `New request for ${item.subject} `}
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))
                ) : (
                  <div className="text-center">No Notifications</div>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <ConfirmPopupModal
        header={"Are you sure you want to Logout ?"}
        id={"ConfirmModal"}
        confirmButtonLabel={"Logout"}
        confirmOnclick={logout}
      ></ConfirmPopupModal>{" "}
    </nav>
  );
}
