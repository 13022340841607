import React from "react";
import VDiv from "./vdiv";
import PagePopupHeader from "../general/page-popup-header";

export default function PagePopup({
  showHeader,
  showBackNavigation,
  title,
  subTitle,
  onBack,
  children,
  customHeader,
  className,
}) {
  return (
    <VDiv className="page-popup" onClick={onBack}>
      <VDiv className="popup" onClick={(e)=>e.stopPropagation()}>
        {showHeader ? (
          <PagePopupHeader
            goToPrevPage={false}
            title={title}
            subTitle={subTitle}
            showBackNavigation={showBackNavigation}
            onBack={onBack}
          />
        ) : null}
        {customHeader ? customHeader : null}
        <VDiv
          className={`popup-content h-90vh overflow-auto ${
            className ? className : ""
          }`}
        >
          {children}
        </VDiv>
      </VDiv>
    </VDiv>
  );
}
