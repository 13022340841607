import React from "react";
import FormPopup from "./form-popup";
import ProcessLoader from "../general/process-loader";
import { nameField, email_validation, phone_validation, password_validation } from "../../utils/inputValidations";
import { Input } from "../inputs/input";
import { useForm, FormProvider } from 'react-hook-form'


export default function DepartmentUserPopup({ title, onSave, onCancel, formData, hook, placeHolder, departmentId }) {

    const { handleInputChange, handleSubmit, apiInProgress } = hook(onSave, formData, departmentId);

    const firstName = nameField("firstName", "firstName", "First Name", "Enter first name", true, 3, 100)
    const lastName = nameField("lastName", "lastName", "Last Name", "Enter last name", true, 3, 100)

    const methods = useForm(
        {
            defaultValues: formData ? {
                name: formData.name,
                description: formData.description
            } : {}
        }
    )

    const onSubmit = methods.handleSubmit(data => {
        handleSubmit(data)
    })
    return (
        <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel}>
            <FormProvider {...methods}>
                <form
                    onSubmit={e => e.preventDefault()}
                    onChange={event => handleInputChange(event)}
                    noValidate
                    autoComplete="off"
                    className="overflow-auto d-flex justify-content-between flex-column department-user-from-popup-height"
                >
                    <Input id="name" {...firstName} className="mb-2 " />
                    <Input id="name" {...lastName} className="mb-2" />
                    <Input id="name" {...email_validation} className="mb-2 " />
                    <Input id="name" {...password_validation} className="mb-2 " />
                    <Input id="name" {...phone_validation} className="mb-2" />
                    <div className='form-footer mt-2 d-flex gap-3'>
                        <button type="button" className="mb-3 w-100 btn btn-outline-secondary r-30px" onClick={() => onCancel()}>Cancel</button>
                        <button className={`edc-btn-secondary mb-3 w-100 `} onClick={onSubmit}>Save</button>
                    </div>
                    {apiInProgress && <ProcessLoader />}
                </form>
            </FormProvider>
        </FormPopup>
    )
}