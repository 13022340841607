import React from 'react'
import PagePopup from '../containers/page-popup'



export default function FormPopup({onSave, onCancel, children, title, showAddAnother = true}) {
    return(
        <PagePopup onBack={onCancel} showHeader showBackNavigation title={title}>
            {children}
        </PagePopup>
    )
}