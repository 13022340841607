import React, { useState } from "react"
import { useParams } from "react-router-dom"
import FormPopup from "./form-popup";
import ProcessLoader from "../general/process-loader";
import { saveStaff, updateStaff, getUserExist } from "../../service/StaffService"
import Select from "../inputs/select";
import { nameField, email_validation, password_validation } from "../../utils/inputValidations";
import { Input } from "../inputs/input";
import { useForm, FormProvider } from 'react-hook-form'
import FileSelector from "../inputs/file-selector";
import { getUploadApiUrl } from '../../service/ApiUrls'
import { upload } from '../../service/Service'

export default function StaffPopup({ id, title, onSave, onCancel, formData, departments }) {

    const firstNameInputField = nameField("firstName", "firstName", "First name", "Enter first name", true, 3, 100)
    const lastNameInputField = nameField("lastName", "lastName", "Last name", "Enter last name", true, 1, 100)
    const phoneInputField = nameField("phoneNumber", "phoneNumber", "Mobile number", "Enter mobile number", true, 10, 10)
    const [userExist, setUserExist] = useState(false)

    const methods = useForm(
        {
            defaultValues: formData ? {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                department: formData.department,
                imageUrl: formData.imageUrl
            } : {}
        }
    )



    const { tenantId, locationId } = useParams();

    const newData = { firstName: "", lastName: "", token: "", email: "", phoneNumber: "" }

    const [data, setData] = useState(formData)

    const [apiInProgress, setApiInProgress] = useState(false)

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)


    const onSubmit = methods.handleSubmit(data => {
        handleSubmit(data)
    })

    const onFileSelect = (files) => {
        setFiles(files)
    }

 
    const handleInputChange = (event) => {
        event.stopPropagation()

        setData((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
    }

    const uploadFile = async () => {
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            tenantId,
            locationId,
            userId: localStorage.getItem("userId"),
            file: file
        }
        let response = await upload(getUploadApiUrl(tenantId), data)
        setUploadInProgress(false)
        if (response) {
            return response.mediaUrl;
        }
    }

    const handleSubmit = async (data) => {
        setApiInProgress(true)
        let payload = { user: data, department: { id: data.department }, location: { id: locationId }, tenantId }
        if (files && files.length > 0) {
            payload.user.imageUrl = await uploadFile()
        }
        if (formData && formData.id) {
            payload.id = formData.id
        }
        if (formData && formData.userId) {
            payload.user.id = formData.userId
        }
        if (formData.id) {
            await updateStaff(tenantId, locationId, payload)
        } else {
            const response = await getUserExist(data.email)
            if (response.userExist) {
                setApiInProgress(false)
                setUserExist(true)
                return
            }
            await saveStaff(tenantId, locationId, payload)
        }
        setApiInProgress(false)
        if (onSave) {
            onSave(payload)
        }
        setData({ ...newData })
    }

    return (
        <FormPopup id={id} title={title} onSave={handleSubmit} onCancel={onCancel}>
            <FormProvider {...methods}>
                <form
                    onSubmit={e => e.preventDefault()}
                    onChange={event => handleInputChange(event)}
                    noValidate
                    autoComplete="off"
                    className="staff-form-popup-height"
                >
                    <Input placeholder="First name" {...firstNameInputField} className="mb-3" />
                    <Input placeholder="Last name" {...lastNameInputField} className="mb-3" />
                    <Input showMessage={userExist} message="User already exist" {...email_validation} className="mb-3" disabled={data.id ? true : false} />
                    <Input {...phoneInputField} className="mb-3" />
                    {!data.id && <Input {...password_validation}  className="mb-3" showEyeIcon="true"
              inDiv={"d-flex align-items-center position-relative"}/>}
                   
                    <Select id="department" className="mb-3 col-md-12" name="department" label="Select Department" list={departments} valueProperty="id" />
                    <FileSelector name="attachment" existingFiles={data.files} onFileSelect={onFileSelect} label="Upload icon" showProgress={uploadInProgress} />

                    <div className='form-footer mt-3'>
                        <button className={`edc-btn-secondary mb-3 col-md-12 mt-3`} onClick={onSubmit}>Save</button>
                        <button type="button" className="mb-3 col-md-12 btn btn-outline-secondary" onClick={() => onCancel()}>Cancel</button>
                    </div>
                    {apiInProgress && <ProcessLoader />}
                </form>
            </FormProvider>
        </FormPopup>
    )
}