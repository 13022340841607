import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { login, postNotificationToken } from "../service/LoginAPI";

import { useForm } from "react-hook-form";
import { handleGetFirebaseToken } from "../App";
import { getAdminDetails } from "../service/BlockService";

export default function useLogin() {
  const navigate = useNavigate();
  const methods = useForm();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiInProgress, setApiInProgress] = useState(false);
  const [notifyToken, setNotifyToken] = useState({});

  const onSubmit = methods.handleSubmit((data) => {
    doLogin(data);
  });

  const onInputChange = (event) => {
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  const handleLoginResponse = async (response) => {
    if (response) {
      if (response.status === 200 && response.data.locationId !== null) {
        const data = response.data;
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("lastName", data.lastName);
        localStorage.setItem("userId", data.id);
        localStorage.setItem("email", data.email);
        localStorage.setItem("tenantId", data.tenantId);
        localStorage.setItem("locationId", data.locationId);
        if (data.access_token) {
          navigate(`/t/${data.tenantId}/l/${data.locationId}`);
        }
      } else if (response.status === 200 && response.data.locationId === null) {
        setErrorMessage("No Users Found");
        setShowErrorMessage(true);
      } else if (response.status === 401) {
        setErrorMessage("Invalid credentials.");
        setShowErrorMessage(true);
      }
      const details = await getAdminDetails(localStorage.getItem("tenantId"));
      localStorage.setItem("BusinessName", details.name);
      localStorage.setItem("BusinessImage", details.imageUrl);
      setTimeout(() => {
        token();
      }, [2000]);
    } else {
      setErrorMessage("Something went wrong. Try again later.");
      setShowErrorMessage(true);
    }
  };

  const doLogin = async (data) => {
    setApiInProgress(true);
    const formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("username", data.email.toLowerCase());
    formData.append("password", data.password);
    localStorage.clear();

    const response = await login(formData);
    await handleLoginResponse(response);
    setApiInProgress(false);
    await handleGetFirebaseToken();
  };
  const token = () => {
    notifyToken.userId = localStorage.getItem("tenantId");
    notifyToken.departmentId = "";
    notifyToken.token = localStorage.getItem("notifyToken");
    notifyToken.deviceId = "";
    notifyToken.role = "SUPER-ADMIN";
    postNotificationToken(notifyToken);
  };
  useEffect(() => {
    token();
  }, []);

  return {
    onSubmit,
    methods,
    errorMessage,
    showErrorMessage,
    onInputChange,
    apiInProgress,
    setNotifyToken,
  };
}
