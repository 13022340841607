import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import VDiv from "../containers/vdiv";


import PageTitle from "../elements/texts/page-title";
import { getFormattedDateAndTime } from "../../utils/formatters";
import HDiv from "../containers/hdiv";
import { getProductRequestDetail } from "../../service/ProductRequestService";

export default function ProductRequestDetailSection({ enable }) {
  const { tenantId, locationId, id } = useParams();

  const [serviceRequest, setServiceRequest] = useState(undefined);

  const fetchServiceRequest = async () => {
    const response = await getProductRequestDetail(tenantId, locationId, id);
    
    setServiceRequest(response);
  };

  useEffect(() => {
    fetchServiceRequest();
  }, []);
  

  

  function addTimeToCurrentTime(timeString, timestamp) {
    // Split the time string into hours and minutes
    const [hoursStr, minutesStr] = timeString.split(":");
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    // Create a new Date object using the provided timestamp
    const currentDate = new Date(timestamp);
    const currentHours = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();
    // Calculate the new time
    const newHours = currentHours + hours;
    const newMinutes = currentMinutes + minutes;

    // Create a new Date object with the updated hours and minutes
    const newDate = new Date(timestamp);
    newDate.setHours(newHours, newMinutes);

    // Format the time to display as "hh:mm AM/PM"
    const formattedTime = newDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return formattedTime; // Return the new time as a string
  }

  
  return (
    <>
      {!enable && (
        <VDiv>
          {serviceRequest && (
            <>
              <VDiv className={"row align-items-center"}>
                <VDiv className={"col-lg-6 col-12"}>
                  <PageTitle text={serviceRequest.product.name} />
                  <span className="mt-2">
                    <p className="text-danger fw-bold">
                      {serviceRequest.room.name}
                    </p>
                    <p className="text-wrap fw-bold">
                      {"(" +
                        serviceRequest.room.floorId.blockId.name +
                        ", " +
                        serviceRequest.room.floorId.name +
                        ")"}
                    </p>
                  </span>
                  <p>{serviceRequest.comment} </p>
                  <div className="timeline timeline-one-side mt-3">
                    {serviceRequest.histories.map((item) => (
                      <StatusBlock key={item.id} item={item} />
                    ))}
                  </div>
                </VDiv>
                <VDiv className={"col-lg-6 col-12 h-100"}>
                  <div className="p-md-3 p-2 border border-2 rounded shadow-md service-request-details-card">
                   
                    <div className="d-flex gap-2 py-2">
                      <h5 className="fw-normal col-lg-5 col-5">Requested on</h5>
                      <h5 className="col-lg-6 col-6">
                        {getFormattedDateAndTime(
                          new Date(serviceRequest.histories[0].createdOn)
                        )}
                      </h5>
                    </div>
                    <div className="d-flex gap-2 py-2">
                      <h5 className="fw-normal col-lg-5 col-5">
                        Current Status
                      </h5>
                      <h5 className="col-lg-6 col-6 text-danger">
                        {serviceRequest.histories[0].status.displayName}
                      </h5>
                    </div>
                    <div className="d-flex gap-2 py-2">
                      <h5 className="fw-normal col-lg-5 col-5">User name</h5>
                      <h5 className="col-lg-6 col-6">
                        {serviceRequest.userName
                          ? serviceRequest.userName
                          : "-"}
                      </h5>
                    </div>
                 
                    <div className="d-flex gap-2 py-2">
                      <h5 className="fw-normal col-lg-5 col-5">User number</h5>
                      <h5 className="col-lg-6 col-6">
                        {serviceRequest.phoneNumber
                          ? serviceRequest.phoneNumber
                          : "-"}
                      </h5>
                    </div>
                   
                    <div className="d-flex gap-2 py-2">
                      <h5 className="fw-normal col-lg-5 col-5">Assigned to</h5>
                      <h5 className="col-lg-6 col-6">
                        {serviceRequest.assignedTo
                          ? serviceRequest.assignedTo.user.firstName +
                            " " +
                            serviceRequest.assignedTo.user.lastName
                          : "-"}
                      </h5>
                    </div>
                    <div className="d-flex gap-2 py-2">
                      <h5 className="fw-normal col-lg-5 col-5">Assigned on</h5>
                      <h5 className="col-lg-6 col-6">
                        {serviceRequest.assignedTo
                          ? getFormattedDateAndTime(
                              new Date(serviceRequest.assignedTo.createdOn)
                            )
                          : "-"}
                      </h5>
                    </div>
                    <div className="d-flex gap-2 py-2">
                      <h5 className="fw-normal col-lg-5 col-5">ETC</h5>
                      <h5 className="col-lg-6 col-6">
                        {serviceRequest.product.turnaroundTime !== null
                          ? `${addTimeToCurrentTime(
                              serviceRequest.product.turnaroundTime,
                              serviceRequest.createdOn
                            )}`
                          : "-"}
                      </h5>
                    </div>
                  </div>
                </VDiv>
              </VDiv>
            </>
          )}
        </VDiv>
      )}

    </>
  );
}

function StatusBlock({ item }) {
  const [openFile, setOpenFile] = useState(false);

  const [selectedFile, setSelectedFile] = useState();

  const onFileClick = (file) => {
    setSelectedFile(file);
    setOpenFile(true);
  };

  const closeFile = (event) => {
    event.stopPropagation();
    setOpenFile(false);
  };

  return (
    <div className="timeline-block mb-3">
      <span className="timeline-step">
        <img src="/assets/images/icons/completed-icon.png" alt="Done" />
      </span>
      <div className="timeline-content">
        <h6 className="text-dark text-sm font-weight-bold mb-0">
          {item.status.displayName}
          {item.assignedTo && (
            <span>
              {" "}
              to{" "}
              <span className="user-name">
                {item.assignedTo.user.firstName} {item.assignedTo.user.lastName}
              </span>
            </span>
          )}
        </h6>
        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
          {getFormattedDateAndTime(new Date(item.createdOn))}
        </p>
        {item.comment && <p className="comment">{item.comment}</p>}
        <HDiv className="mt-3 mb-3">
          {item.attachments && item.attachments.map((attachment) => (
            <VDiv
              onClick={() => onFileClick(attachment)}
              className="file-preview"
              key={attachment.id}
              style={{
                backgroundImage: `url(${attachment.attachment.mediaUrl})`,
              }}
            ></VDiv>
          ))}
        </HDiv>
      </div>

      {openFile ? (
        <VDiv className="show-file-container">
          <img
            className="file"
            alt={selectedFile.attachment.name}
            src={selectedFile.attachment.mediaUrl}
          />
          <img
            className="close-btn"
            src="/assets/images/icons/icon-close-white.png"
            alt="close"
            onClick={(event) => closeFile(event)}
          />
        </VDiv>
      ) : null}
    </div>
  );
}
