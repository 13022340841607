import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";

import Layout from "../components/layout/layout";
import useProducts from "../hooks/useProducts";
import Popover from "../components/popups/popover";
import Toast from "../components/general/toast";
import ProductFormPopup from "../components/popups/product-form-popup";
import Avatar from "../components/general/avatar";
import DeleteButton from "../components/inputs/delete-button";
import DataTable from "react-data-table-component";
import EditButton from "../components/inputs/edit-button";

export default function Products() {
  const {
    showForm,
    list,
    formData,
    handleSubmit,
    loading,
    handleAddClick,
    handleCancelClick,
    categories,
    departments,
    handleDeleteClick,
    handleEditClick,
    showMessage,
    showConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    message,
    selectedItem,
    setEdit,
    setFormData,
    
  } = useProducts();

  const columns = [
    {
      name: "S. No",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {index + 1}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      minWidth: "150px",

      sortable: false,
      cell: (row) => (
        <span className="avatar-container capitalize">
          <Avatar image={row.coverImage} name={row.name} />{" "}
          <strong>{row.name}</strong>
        </span>
      ),
    },
    {
      name: "Category",
      selector: (row) => (
        <span className={`capitalize ${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.category ? row.category.name : ""}
        </span>
      ),
      sortable: false,
    },
    {
      name: "Department",
      selector: (row) => (
        <span className={`capitalize ${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.department ? row.department.name : ""}
        </span>
      ),
      sortable: false,
    },
    {
      name: "Price",
      // selector: (row) => row.price,
      selector: (row) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.price ? row.price : ""}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Old Price",
      // selector: (row) => row.wasPrice,
      selector: (row) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.wasPrice ? row.wasPrice : ""}
        </span>
      ),
      center: "true",
    },
    {
      name: "Edit",
      selector: (row) =>
        !row.deleted && (
          <EditButton
            onClick={(event) => {
              handleEditClick(event, row);
              setEdit(true);
            }}
          />
        ),
      width: "80px",
      center: "true",
    },
    {
      name: "Delete",
      selector: (row) =>
        !row.deleted && (
          <DeleteButton onClick={(event) => handleDeleteClick(event, row)} />
        ),
      width: "80px",
      center: "true",
    },
  ];

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card
            title="Products manager"
            subTitle="Add/Edit/Delete your products."
            modalId="masterTypeModal"
            showAddButton
            onAdd={handleAddClick}
          >
            <DataTable data={list} columns={columns} pagination />
          </Card>
          {showForm && (
            <ProductFormPopup
              placeHolder="Product"
              onCancel={handleCancelClick}
              onSave={handleSubmit}
              formData={formData}
              title="Product"
              categories={categories}
              departments={departments}
              setFormData={setFormData}
            />
          )}
        </>
      )}
      {showConfirmation && (
        <Popover
          title={`Confirm deleting '${selectedItem.name}'`}
          showActions
          saveLabel="Delete"
          onCancel={onDeleteCancel}
          onClose={onDeleteCancel}
          onSave={onDeleteConfirm}
        >
          <p className="mt-1">{`Are you sure that you want to delete the product '${selectedItem.name}'?`}</p>
        </Popover>
      )}

      {showMessage && <Toast text={message} className="success" />}
    </Layout>
  );
}
