import React, {useRef} from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import Layout from "../components/layout/layout";
import useRoomDetail from "../hooks/useRoomDetail";
import QRCode from "react-qr-code";
import PageTitle from "../components/elements/texts/page-title";
import ReactToPrint from "react-to-print";
import Button from "../components/inputs/button";
import TabBar from "../components/layout/tab-bar";
import VDiv from "../components/containers/vdiv";
import { useParams } from "react-router-dom"


export default function RoomDetail() { 

    const { tenantId, roomId } = useParams();

    const {services, toggleSelection, loading, onSaveServices, qrCode, name, onTabClick, selectedTab} = useRoomDetail()

    let printRef = useRef()

    return(
        <Layout>
            <PageTitle text={name} />
            <TabBar className={"mb-3 mt-3"} selectedIndex={selectedTab} onClick={onTabClick} items={["Room Detail"]} />
        {loading ? <Loader /> : 
            <>
            {selectedTab === 0 &&
                <Card showAddButton={false}>
                    <span>/t/{tenantId}/r/{roomId}</span>
                    <VDiv className="v-container qr-container">
                        <QRCode value={qrCode} ref={(ele) => printRef = ele} />
                        <ReactToPrint
                            trigger={() => <Button label="PRINT" />}
                            content={() => printRef}
                        />
                    </VDiv>
                </Card>
            }
            {selectedTab === 1 &&
                <Card title="Services" subTitle="Add/Edit/Delete room services." buttonLabel="Save" onAdd={onSaveServices}>
                    <div className="row">
                        <div className="col col-sm-6 list">
                            <h5>Available Services</h5>
                            <div className="row">
                                {
                                    services && services.length > 0 && services.map((data) => 
                                        data.deleted ? 
                                        <div className="col-sm-12" key={data.service.id} onClick={() => toggleSelection(data, false)}>
                                            <span className="list-item">{data.service.name}</span>
                                        </div> : null
                                    )
                                }
                            </div>
                        </div>

                        <div className="col col-sm-6 list">
                            <h5>Selected Room Services</h5>
                            <div className="row">
                                {
                                    services && services.length > 0 && services.map((data) => 
                                        !data.deleted ? 
                                        <div className="col-sm-12" key={data.service.id} onClick={() => toggleSelection(data, true)}>
                                            <span className="list-item">{data.service.name}</span>
                                        </div> : null
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Card>
            }
            </>
        }
    </Layout>
    )
}