import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import IconBox from "../components/general/box/icon-box";
import NoDataMessage from "../components/general/NoDataMessage";
import Layout from "../components/layout/layout";
import NameDescriptionPopup from "../components/popups/name-description-form-popup";
import useCategoryDetail from "../hooks/useCategoryDetail";
import useServiceForm from "../hooks/forms/useServiceForm";

export default function CategoryDetail() {
  const {
    showForm,
    formData,
    handleSubmit,
    category,
    loading,
    handleAddClick,
    handleCancelClick,
    setShowForm,
  } = useCategoryDetail();

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card
            title={category.name}
            subTitle="Add/Edit/Delete Category services."
            showAddButton={false}
            onAdd={handleAddClick}
          >
            <div className="row">
              {category.services && category.services.length > 0 ? (
                category.services.map((data, index) => (
                  <div key={index} className="col-sm-3 col-lg-3 mb-4">
                    <IconBox data={data} />
                  </div>
                ))
              ) : (
                <NoDataMessage showAddButton={false} onAdd={handleAddClick} />
              )}
            </div>
          </Card>
          {showForm && (
            <NameDescriptionPopup
              placeHolder={"category service"}
              hook={useServiceForm}
              onCancel={handleCancelClick}
              onSave={handleSubmit}
              formData={formData}
              title="Service"
              showForm={setShowForm}
            />
          )}
        </>
      )}
    </Layout>
  );
}
