import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function useMenu() {
  const [items, setItems] = useState([]);

  const { tenantId, locationId } = useParams();

  const generateMenus = (masterTypes) => {
    let items = [];
    items.push({
      menuName: "menuName0",
      title: "Dashboard",
      link: `#/t/${tenantId}/l/${locationId}`,
      icon: "bx-home-circle",
      type: "menu",
      active: true,
    });
    items.push({
      menuName: "menuName",
      title: "Requests",
      link: `#/t/${tenantId}/l/${locationId}/service-requests`,
      icon: "bxs-cog",
      type: "menu",
      active: false,
    });
 
    items.push({
      menuName: "menuName1",
      title: "Masters",
      type: "menu-header",
      active: false,
    });
    items.push({
      menuName: "menuName2",
      title: "Categories",
      link: `#/t/${tenantId}/l/${locationId}/categories`,
      icon: "bx-category",
      type: "menu",
      active: false,
    });
    items.push({
      menuName: "menuName3",
      title: "Services",
      link: `#/t/${tenantId}/l/${locationId}/services`,
      icon: "bx-cog",
      type: "menu",
      active: false,
    });
    items.push({
      menuName: "menuName4",
      title: "Departments",
      link: `#/t/${tenantId}/l/${locationId}/departments`,
      icon: "bxs-cog",
      type: "menu",
      active: false,
    });
    items.push({
      menuName: "menuName5",
      title: "Blocks",
      link: `#/t/${tenantId}/l/${locationId}/blocks`,
      icon: "bx-category",
      type: "menu",
      active: false,
    });
    items.push({
      menuName: "menuName6",
      title: "Staffs",
      link: `#/t/${tenantId}/l/${locationId}/staffs`,
      icon: "bx-user",
      type: "menu",
      active: false,
    });
    items.push({
      menuName: "menuName7",
      title: "Products",
      link: `#/t/${tenantId}/l/${locationId}/products`,
      icon: "bx-cog",
      type: "menu",
      active: false,
    });
    items.push({
      menuName: "menuName8",
      title: "Stock Management",
      link: `#/t/${tenantId}/l/${locationId}/stock`,
      icon: "bx-trending-up",
      type: "menu",
      active: false,
    });

    if (localStorage.getItem("menuName") !== null) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].active === true) {
          items[i].active = false;
        }
      }
      for (let i = 0; i < items.length; i++) {
        if (`#/${window.location.href.split("/#/")[1]}` === items[i].link) {
          items[i].active = true;
          localStorage.setItem("menuName", items[i].menuName);
        } else if (
          `#/${window.location.href.split("/#/")[1]}`.includes("/sr/") ||
          `#/${window.location.href.split("/#/")[1]}`.includes("/category/") ||
          `#/${window.location.href.split("/#/")[1]}`.includes("/block/") ||
          `#/${window.location.href.split("/#/")[1]}`.includes(
            "/department/"
          ) ||
          `#/${window.location.href.split("/#/")[1]}`.includes("/floor/") ||
          `#/${window.location.href.split("/#/")[1]}`.includes("/staff/") ||
          `#/${window.location.href.split("/#/")[1]}`.includes("/room/")

        ) {
          if (localStorage.getItem("menuName") === items[i].menuName) {
            items[i].active = true;
          }
        } else {
          items[i].active = false;
        }
      }
    }

    setItems(items);
  };

  useEffect(() => {
    const newArr = [...items];
    for (let j = 0; j < newArr.length; j++) {
      if (`#/${window.location.href.split("/#/")[1]}` === newArr[j].link) {
        newArr[j].active = true;
        localStorage.setItem("menuName", newArr[j].menuName);
      } else if (
        `#/${window.location.href.split("/#/")[1]}`.includes("/sr/") ||
        `#/${window.location.href.split("/#/")[1]}`.includes("/category/") ||
        `#/${window.location.href.split("/#/")[1]}`.includes("/block/") ||
        `#/${window.location.href.split("/#/")[1]}`.includes("/department/") ||
        `#/${window.location.href.split("/#/")[1]}`.includes("/floor/") ||
        `#/${window.location.href.split("/#/")[1]}`.includes("/staff/") ||
        `#/${window.location.href.split("/#/")[1]}`.includes("/room/")
      ) {
        if (localStorage.getItem("menuName") === newArr[j].menuName) {
          newArr[j].active = true;
        }
      } else {
        newArr[j].active = false;
      }
    }

    setItems(newArr);
  }, [window.location.href]);

  useEffect(() => {
    generateMenus();
  }, []);

  return { items };
}
