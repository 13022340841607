import {
    createDepartmentUserApiUrl,
    getDeleteDepartmentApiUrl,
    getDepartmentServicesApiUrl,
    getDepartmentUserApiUrl,
    getDepartmentsApiUrl,
    getProductsBasedOnDepartment,
    getSaveDepartmentApiUrl,
    getSaveDepartmentServicesApiUrl
} from "./ApiUrls";
import { post, get, put, Delete } from "./Service";


export const getDepartments = async (tenantId, locationId) => {
    const response = await get(getDepartmentsApiUrl(tenantId, locationId));
    if (!response) {
        return []
    }
    return response;
};

export const saveDepartment = async (tenantId, locationId, data) => {
    const response = await post(getSaveDepartmentApiUrl(tenantId, locationId), data);
    return response;
};

export const updateDepartment = async (tenantId, locationId, data) => {
    const response = await put(getSaveDepartmentApiUrl(tenantId, locationId), data);
    return response;
};

export const deleteDepartment = async (tenantId, locationId, id) => {
    const response = await Delete(getDeleteDepartmentApiUrl(tenantId, locationId, id));
    return response;
};

export const saveDepartmentServices = async (tenantId, locationId, departmentId, data) => {
    const response = await post(getSaveDepartmentServicesApiUrl(tenantId, locationId, departmentId), data);
    return response;
};

export const getDepartmentServices = async (tenantId, locationId, departmentId) => {
    const response = await get(getDepartmentServicesApiUrl(tenantId, locationId, departmentId));
    return response;
};

export const createDepartmentUser = async (tenantId, locationId, departmentId, data) => {
    const response = await post(createDepartmentUserApiUrl(tenantId, locationId, departmentId), data);
    return response;
}

export const getDepartmentUser = async (tenantId, locationId, departmentId) => {
    const response = await get(getDepartmentUserApiUrl(tenantId, locationId, departmentId));
    return response;
}

export const getProductsByDepartmentId = async(tenantId,locationId,departmentId)=>{
    const response  =await get(getProductsBasedOnDepartment(tenantId,locationId,departmentId))
    return response;
}