import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import Layout from "../components/layout/layout";

import {
  saveStaffServices,
  getStaffServices,
  getStaff,
  staffOnlyCount,
} from "../service/StaffService";
import HDiv from "../components/containers/hdiv";
import DataTable from "react-data-table-component";
import NoDataMessage from "../components/general/NoDataMessage";
import {
  getServiceRequestByStatus,
  getServiceRequestStatuses,
} from "../service/ServiceRequestService";
import { getFormattedDateAndTime } from "../utils/formatters";
import { getServiceRequestDetaillUrl } from "../utils/Constant";

export default function StaffDetail() {
  const navigate = useNavigate();

  const [services, setServices] = useState([]);

  const { tenantId, locationId, staffId } = useParams();

  const [loading, setLoading] = useState(true);

  const [staff, setStaff] = useState({ user: { firstName: "", lastName: "" } });

  const [enable, setEnable] = useState(false);

  const [list, setList] = useState([]);

  const [filteredStatus, setFilteredStatus] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [staffServiceCount, setStaffServiceCount] = useState();

  const fetchStaff = async () => {
    const response = await getStaff(tenantId, locationId, staffId);
    setStaff(response);
  };

  const onSaveServices = async () => {
    setLoading(true);
    await saveStaffServices(tenantId, locationId, staffId, services);
    setLoading(false);
  };

  const fetchServices = async () => {
    const response = await getStaffServices(tenantId, locationId, staffId);
    let filteredReponse = response.filter(
      (item) => item.service.deleted === false
    );
    setServices(filteredReponse);
    setLoading(false);
  };

  const toggleSelection = (data, deleted) => {
    data.deleted = deleted;
    setServices([...services]);
  };

  useEffect(() => {
    setLoading(true);
    fetchStaff();
    fetchServices();
  }, [locationId]);

  const handleClickStaffSection = (event, section) => {
    if (section === "Services") {
      setEnable(false);
    } else {
      setEnable(true);
    }
  };

  const handleRowClick = (item) => {
    navigate(getServiceRequestDetaillUrl(tenantId, locationId, item.id));
  };

  const columns = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}
        >
          {" "}
          {index + 1}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Room Name",
      id: "tableHeader",
      cell: (row) => (
        <span>
          {/* {" "}
          <span className="text-danger">
            {row.room.floorId.blockId.name + "/" + row.room.floorId.name + "/"}
          </span>
          <p>{row.room.name}</p> */}
          <span onClick={() => handleRowClick(row)}
          >
            <p className="text-danger fs-13px fw-bold">{row.room.name}</p>
            <p className="text-wrap">{"(" + row.room.floorId.blockId.name + "," + row.room.floorId.name + ")"}</p>
          </span>
        </span>
      ),
      sortable: false,
    },
    {
      name: "Assigned On",
      id: "tableHeader",
      selector: (row) => <span onClick={() => handleRowClick(row)}
      >{
          getFormattedDateAndTime(new Date(row.assignedTo.createdOn))}</span>,
      sortable: false,
      width: "200px",
    },
    {
      name: "Service Name",
      id: "tableHeader",
      cell: (row) => <span onClick={() => handleRowClick(row)}
      > {row.service.name}</span>,
      center: "true",
    },
    {
      name: "Status",
      id: "tableHeader",
      cell: (row) => <span onClick={() => handleRowClick(row)}
      > {row.status.displayName}</span>,
      center: "true",
    },
  ];
  const handleServiceRequestByStatus = async () => {
    if (filteredStatus !== "") {
      const response = await getServiceRequestByStatus(staffId, filteredStatus);
      setList(response);
    }
  };

  useEffect(() => {
    handleServiceRequestByStatus();
  }, [filteredStatus, enable]);
  useEffect(() => {
    fetchServiceRequestStatuses();
  }, []);
  const fetchServiceRequestStatuses = async () => {
    const response = await getServiceRequestStatuses(tenantId, locationId);
    setStatuses(response);
    setFilteredStatus(response[1].id);
  };
  const staffOnlyCountData = async () => {
    const response = await staffOnlyCount(staffId);
    setStaffServiceCount(response);
  };
  useEffect(() => {
    staffOnlyCountData();
  }, []);
  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HDiv className={`flex tab-bar my-3 pb-3`}>
            <div className="d-flex gap-4 justify-content-start ms-4 mt-3">
              {enable ? (
                <div
                  className="shadow-none pull-right mb-0 capitalize"
                  onClick={(event) => {
                    handleClickStaffSection(event, "Services");
                  }}
                >
                  Services
                </div>
              ) : (
                <div
                  className="pull-right mb-0 text-primary border-bottom border-primary border-4 pb-2 fw-bold"
                  onClick={(event) => {
                    handleClickStaffSection(event, "Services");
                  }}
                >
                  Services
                </div>
              )}
              <HDiv className={"justify-content-between"}>
                {!enable ? (
                  <div
                    className="shadow-none pull-right mb-0"
                    onClick={(event) => {
                      handleClickStaffSection(event, "Requests");
                    }}
                  >
                    Requests
                  </div>
                ) : (
                  <div
                    className=" pull-right mb-0 border-bottom border-primary text-primary border-4 pb-2 fw-bold"
                    onClick={(event) => {
                      handleClickStaffSection(event, "Requests");
                    }}
                  >
                    Requests
                  </div>
                )
                }
              </HDiv>
            </div>
          </HDiv>
          {enable === false ? (
            <Card
              title={staff.user.firstName + " " + staff.user.lastName}
              subTitle={
                "Add/Delete the services of " +
                staff.user.firstName +
                " " +
                staff.user.lastName +
                "."
              }
              buttonLabel="Save"
              onAdd={onSaveServices}
            >
              <div className="row">
                <div className="col col-sm-6 list">
                  <h5>Available Services</h5>
                  <div className="row">
                    {services &&
                      services.length > 0 &&
                      services.map((data) =>
                        data.deleted ? (
                          <div
                            className="col-sm-12"
                            key={data.service.id}
                            onClick={() => toggleSelection(data, false)}
                          >
                            <span className="list-item">
                              {data.service.name}
                            </span>
                          </div>
                        ) : null
                      )}
                  </div>
                </div>

                <div className="col col-sm-6 list">
                  <h5>Selected Staff Services </h5>
                  <div className="row">
                    {services &&
                      services.length > 0 &&
                      services.map((data) =>
                        !data.deleted ? (
                          <div
                            className="col-sm-12"
                            key={data.service.id}
                            onClick={() => toggleSelection(data, true)}
                          >
                            <span className="list-item">
                              {data.service.name}
                            </span>
                          </div>
                        ) : null
                      )}
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            <>
              <div className="px-3 pb-2 justify-content-center row row-cols-lg-5 row-cols-sm-3 row-cols-1 g-3">
                <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
                  Assigned:
                  <p className="text-primary fw-bold ps-2">
                    {staffServiceCount.assignedCount}
                  </p>
                </div>
                <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
                  In-Progress:
                  <p className="text-primary fw-bold ps-2">
                    {staffServiceCount.inProgressCount}
                  </p>
                </div>
                <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
                  Completed:
                  <p className="text-primary fw-bold ps-2">
                    {staffServiceCount.completedCount}
                  </p>
                </div>
                {/* <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
                  Closed:
                  <p className="text-primary fw-bold ps-2">
                    {staffServiceCount.closedCount}
                  </p>
                </div> */}
                <div className="d-flex fw-bold col justify-content-center border shadow-sm rounded-3 py-2">
                  Total:
                  <p className="text-primary fw-bold ps-2">
                    {staffServiceCount.totalCount - staffServiceCount.closedCount}
                  </p>
                </div>
              </div>
              <Card showAddButton={false}>
                <div className="d-flex justify-content-between w-100 pb-3">
                  <div className="font-weight-bold">
                    {staff.user.firstName + " " + staff.user.lastName}{" "}
                  </div>
                  <div className=" d-flex flex-wrap w-auto flex-row">
                    <h6 className="my-o pt-2 pe-2">Status:</h6>
                    <select
                      name="statuses"
                      className="rounded rounded-5 border mx-1 ps-2"
                      onChange={(e) => {
                        setFilteredStatus(e.target.value);
                      }}
                    >
                      {statuses.map((item, i) => {
                        return (
                          <>
                            {(item.name !== "NEW" && item.name !== "CLOSED") && (
                              <option value={item.id} key={i}>
                                {item.displayName}
                              </option>
                            )}
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {list && list.length > 0 ? (
                  <DataTable
                    data={list}
                    columns={columns}
                    pagination
                    className="dataTable"
                    onRowClicked={handleRowClick}
                  />
                ) : (
                  <NoDataMessage />
                )}
              </Card>
            </>
          )}
        </>
      )}
    </Layout>
  );
}
