import { useState } from "react";
import { useParams } from "react-router-dom";
import { saveFloor, updateFloor } from "../../service/FloorService";
import { getUploadApiUrl } from "../../service/ApiUrls";
import { upload } from "../../service/Service";

export default function useFloorForm(onSave, formData) {
  const { tenantId, locationId, blockId } = useParams();

  const newData = {
    name: "",
    description: "",
    imageUrl: undefined,
    deleted: false,
    tenantId,
    locationId,
    blockId: { id: blockId },
  };

  const [data, setData] = useState(
    formData !== undefined ? { ...formData } : { ...newData }
  );

  const [files, setFiles] = useState([]);

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [apiInProgress, setApiInProgress] = useState(false);

  const handleInputChange = (event) => {
    event.stopPropagation();
    setData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const onFileSelect = (files) => {
    setFiles(files);
  };

  const handleSubmit = async (saveAndAddAnother) => {
    setApiInProgress(true);
    if (files && files.length > 0) {
      data.imageUrl = await uploadFile();
    }
    if (!data.id) {
      await saveFloor(tenantId, locationId, data);
    } else {
      await updateFloor(tenantId, locationId, data);
    }
    setApiInProgress(false);
    if (onSave) {
      onSave(data, saveAndAddAnother);
    }
    setData({ ...newData });
  };

  const uploadFile = async () => {
    setUploadInProgress(true);
    let file = files[0].file;
    const data = {
      tenantId,
      locationId,
      userId: localStorage.getItem("userId"),
      file: file,
    };
    let response = await upload(getUploadApiUrl(tenantId), data);
    setUploadInProgress(false);
    if (response) {
      return response.mediaUrl;
    }
  };

  return {
    handleInputChange,
    handleSubmit,
    onFileSelect,
    data,
    uploadInProgress,
    apiInProgress,
  };
}
