import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getDepartments, deleteDepartment } from "../service/DepartmentService";
import { getDepartmentDetailUrl } from "../utils/Constant";

export default function useDepartments() {
  const [list, setList] = useState([]);

  const [edit, setEdit] = useState(false);

  const [addAdmin, setAddAdmin] = useState(false);

  const [formData, setFormData] = useState(undefined);

  const [loading, setLoading] = useState(true);

  const { tenantId, locationId } = useParams();

  const [showForm, setShowForm] = useState(false);

  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState();

  const [showMessage, setShowMessage] = useState(false);

  const [message, setMessage] = useState("");

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [update, setUpdate] = useState(false);

  const [departmentId, setDepartmentId] = useState(false);


  const handleSubmit = async (response) => {
    setShowForm(false);
    displayMessage(
      `Department '${response.name}' has been ${edit ? "updated" : "saved"
      } successfully.`
    );
    setEdit(false);
    fetchAll();
  };

  const fetchAll = async () => {
    const response = await getDepartments(tenantId, locationId);
    setList(response);
    setLoading(false);
  };

  const handleRowClick = (row) => {
    localStorage.setItem("departmentId", row.id)
    if (row.deleted) {
      return;
    }
    navigate(getDepartmentDetailUrl(tenantId, locationId, row.id, row.name));
  };

  const handleAddClick = () => {
    setEdit(false);
    setAddAdmin(true);
    setFormData(undefined);
    setShowForm(true);
  };

  const handleEditClick = (event, row) => {
    event.stopPropagation();
    if (row.imageUrl) {
      row.files = [{ image: row.imageUrl, name: "GJ Concierge" }];
    } else {
      row.files = [];
    }
    setFormData({ ...row, locationId: localStorage.getItem("locationId") });
    setShowForm(true);
    setUpdate(true)
    setDepartmentId(row.id)

  };

  const handleDeleteClick = (event, row) => {
    event.stopPropagation();
    setSelectedItem(row);
    setShowConfirmation(true);
  };

  const handleCancelClick = () => {
    setShowForm(false);
  };

  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const onDeleteCancel = () => {
    setShowConfirmation(false);
  };

  const onDeleteConfirm = async () => {
    await deleteDepartment(tenantId, locationId, selectedItem.id);
    fetchAll();
    setShowConfirmation(false);
    displayMessage(
      `Department '${selectedItem.name}' has been deleted successfully.`
    );
  };

  useEffect(() => {
    fetchAll();
  }, [locationId]);

  return {
    showForm,
    list,
    formData,
    handleSubmit,
    loading,
    handleAddClick,
    handleRowClick,
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
    showMessage,
    showConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    message,
    selectedItem,
    setEdit,
    addAdmin,
    setAddAdmin,
    update, setUpdate,
    departmentId
  };
}
