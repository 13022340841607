import React from "react";
// import Card from "../components/cards/card";
import Layout from "../components/layout/layout";
import ServiceRequestsSection from "../components/sections/service-requests-section";
import { getServiceRequestStatuses } from "../service/ServiceRequestService";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { getProductRequestStatuses } from "../service/ProductRequestService";
import ProductRequestsSection from "../components/sections/product-requests-section";

export default function Requests({ alert }) {
  const { tenantId, locationId } = useParams();
  const [filteredStatus, setFilteredStatus] = useState("");
  const [productFilteredStatus, setProductFilteredStatus] = useState("");

  const [statuses, setStatuses] = useState([]);
  const [productStatuses, setProductStatuses] = useState([]);

  const fetchServiceRequestStatuses = async () => {
    const response = await getServiceRequestStatuses(tenantId, locationId);
    setStatuses(response);
    setFilteredStatus(response[0].name + " " + response[0].id);
  };

  const fetchProductRequestStatuses = async () => {
    const response = await getProductRequestStatuses(tenantId, locationId);

    setProductStatuses(response);
    setProductFilteredStatus(response[0].name + " " + response[0].id);
  };

  useEffect(() => {
    fetchServiceRequestStatuses();
    fetchProductRequestStatuses();
  }, []);


  const serviceStatus = filteredStatus.split(" ");
  const productStatus = productFilteredStatus.split(" ");

  const data = [
    {
      id: "1",
      tabTitle: "Service Requests",
      tabContent: (
        <>
          <ServiceRequestsSection
            filteredStatus={serviceStatus}
            alert={alert}
          />
        </>
      ),
    },
    {
      id: "2",
      tabTitle: "Product Requests",
      tabContent: (
        <>
          <ProductRequestsSection
            filteredStatus={productStatus}
            alert={alert}
          />
        </>
      ),
    },
  ];
  const [visibleTab, setVisibleTab] = useState(data[0].id);

  const listTitles = data.map((item,i) => (
    <li
      onClick={() => setVisibleTab(item.id)}
      className={
        visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"
      }
      key={item.id}
    >
      {item.tabTitle}
    </li>
  ));
  const listContent = data.map((item,i) => (
    <p style={visibleTab === item.id ? {} : { display: "none" }} key={i}>
      {item.tabContent}
    </p>
  )); 
  return (
    // for closed status remove(item.displayName !== "Closed")
    <Layout>
      <div className="row my-4">
        <div className="col-12 mb-md-0 mb-4">
          <div className="card w-100">
            <div className="card-header d-sm-flex flex-sm-row flex-column w-100 pb-0 d-flex align-content-center justify-between">
              <div className="col-6">
                <div className="tabs">
                  <ul className="tabs-titles">{listTitles}</ul>
                </div>
              </div>
              <div className="flex flex-row align-content-center justify-content-md-end flex-wrap">
                <h6 className="my-o pt-2 pe-2">Status :</h6>

                {visibleTab === data[0].id ? (
                  <select
                    name="statuses"
                    className="form-select w-md-25 w-50  rounded-5 border border-grey"
                    onChange={(e) => {
                      setFilteredStatus(e.target.value);
                    }}
                  >
                    {statuses &&
                      statuses.map(
                        (item, i) =>
                          item.name !== "CLOSED" && (
                            <option value={item.name + " " + item.id} key={i}>
                              {item.displayName}
                            </option>
                          )
                      )}
                  </select>
                ) : (
                  <select
                    name="statuses"
                    className="form-select w-md-25 w-50  rounded-5 border border-grey"
                    onChange={(e) => {
                      setProductFilteredStatus(e.target.value);
                    }}
                  >
                    {productStatuses.map(
                      (item, i) =>
                        item.displayName !== "Closed" && (
                          <option value={item.name + " " + item.id} key={i}>
                            {item.displayName}
                          </option>
                        )
                    )}
                  </select>
                )}
              </div>
            </div>
            <div className="card-body px-0 pb-2 tabs">
              <div className="tab-content">{listContent}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
