import React, { useContext, useEffect, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Departments from "./pages/Departments";
import Categories from "./pages/Categories";
import CategoryDetail from "./pages/CategoryDetail";
import Floors from "./pages/Floors";
import FloorDetail from "./pages/FloorDetail";
import Services from "./pages/Services";
import Staffs from "./pages/Staffs";
import RoomDetail from "./pages/RoomDetail";
import StaffDetail from "./pages/StaffDetail";
import DepartmentDetail from "./pages/DepartmentDetail";
import ServiceRequestDetail from "./pages/ServiceRequestDetail";
import Blocks from "./pages/Blocks";
import Products from "./pages/Products";
import Stock from "./pages/Stock";
import Toast, {
  toaster,
} from "./components/notification-popup/notification-popup";
import { getFirebaseToken, onForegroundMessage } from "./firebase";
import RequireAuth from "./utils/requireAuth";
import { AppState } from "./components/state";
import ProductRequestDetail from "./pages/ProductRequestDetail";
import Requests from "./pages/Requests";

export const handleGetFirebaseToken = async () => {
  const isiOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (isiOS === false) {
    return getFirebaseToken()
      .then((firebaseToken) => {
        localStorage.setItem("notifyToken", firebaseToken);
        return firebaseToken;
      })
      .catch((err) =>
        console.error(
          "An error occurred while retrieving Firebase token. ",
          err
        )
      );
  }
};

function App() {
  const { setAppData } = useContext(AppState);

  // eslint-disable-next-line
  const [notifications, setNotifications] = useState([]);
  // const [notificationCount, setNotificationCount] = useState(false);
  const [alert, setAlert] = useState(false);
  const isiOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (isiOS === false) {
    const [notificationPermission, setNotificationPermission] = useState(
      Notification.permission
    );
    useEffect(() => {
      if (Notification.permission !== "granted") {
        requestNotificationPermission();
      } else if (Notification.permission === "granted") {
        handleGetFirebaseToken();
      }
    }, [notificationPermission]);
    const requestNotificationPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        setNotificationPermission(permission);
      } catch (error) {
        console.error("Error requesting notification permission:", error);
      }
    };
    useEffect(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .catch((error) => {
            console.error("Error registering service worker:", error);
          });
      }
      navigator.serviceWorker.addEventListener("message", handleMessage);

      // eslint-disable-next-line
    }, []);

    const handleMessage = (event) => {
      if (event.data && event.data.type === "background-message") {
      }
    };

    setTimeout(function() {
      document.getElementById("liveToast").classList.replace("show", "hide");
    }, 3000);
    useEffect(() => {
      setAppData(notifications);
      // eslint-disable-next-line
    }, [notifications]);
    useEffect(() => {
      try {
        onForegroundMessage((payload) => {
          const {
            notification: { title, body },
            data,
          } = payload;
          const newNotification = { title, body, data };
          // setNotifications((prevNotifications) => [
          //   ...prevNotifications,
          //   newNotification,
          // ]);
          if (data.superAdminId === localStorage.getItem("tenantId")) {
            setNotifications([newNotification]);
            toaster("success", title, body);
            setAlert(true);
            if (localStorage.getItem("trigger") === "false") {
              localStorage.setItem("trigger", "true");
            } else {
              localStorage.setItem("trigger", "false");
            }
          }
        });
      } catch (err) {
        console.log(
          "An error occurred while setting up foreground message listener. ",
          err
        );
      }
      // eslint-disable-next-line
    }, []);
    useEffect(() => {
      setTimeout(() => {
        setAlert(false);
      }, 500);
    }, [alert]);
  }
  return (
    <>
      <Toast />
      <HashRouter>
        <Routes>
          <Route element={<Login />} path="/" />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Dashboard />{" "}
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Departments />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/departments"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Categories />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/categories"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Products />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/products"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Stock />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/stock"
          />

          <Route
            element={
              <RequireAuth>
                {" "}
                <CategoryDetail />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/category/:categoryId"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Blocks />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/blocks"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Floors />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/block/:blockId/floors/:name"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <FloorDetail />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/floor/:floorId"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Services />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/services"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Staffs />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/staffs"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <StaffDetail />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/staff/:staffId"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <RoomDetail />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/room/:roomId/:name"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <Requests alert={alert} />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/service-requests"
          />
        
          <Route
            element={
              <RequireAuth>
                {" "}
                <DepartmentDetail />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/department/:departmentId/:name"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <ServiceRequestDetail />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/sr/:id"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <ProductRequestDetail />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/pr/:id"
          />
          <Route
            element={
              <RequireAuth>
                {" "}
                <StaffDetail />
              </RequireAuth>
            }
            path="/t/:tenantId/l/:locationId/staff/:staffId"
          />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
