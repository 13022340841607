import React, { useEffect } from "react";
import Layout from "../components/layout/layout";

// import DashboardMessage from "../components/dashboard-message";
import RequestCounts from "../components/request-counts";

export default function Dashboard() {
  useEffect(() => {
    localStorage.setItem("menuName", "menuName0")
  }, [])
  return (
    <Layout>
      <RequestCounts />
      {/* <DashboardMessage /> */}
      {/* <ServiceRequestList /> */}
    </Layout>
  );
}
