import { useState } from 'react'
import { useParams } from "react-router-dom"
import { saveCategory, updateCategory } from '../../service/CategoryService';
import { getUploadApiUrl } from '../../service/ApiUrls'
import { upload } from '../../service/Service'

export default function useCategoryForm(onSave) {

    const { tenantId, locationId } = useParams();

    const [files, setFiles] = useState([])

    const [uploadInProgress, setUploadInProgress] = useState(false)

    const [apiInProgress, setApiInProgress] = useState(false)

    const onFileSelect = (files) => {
        setFiles(files)
    }

    const handleSubmit = async (data) => {
        setApiInProgress(true)

        let payload = { id: data.id, name: data.name, description: data.description, type: data.type, tenantId, locationId, imageUrl: data.imageUrl }
        if (files && files.length > 0) {
            payload.imageUrl = await uploadFile()
        }

        if (payload.id) {
            await updateCategory(tenantId, locationId, payload)
        } else {
            await saveCategory(tenantId, locationId, payload)
        }
        setApiInProgress(false)
        if (onSave) {
            onSave(data)
        }
    }

    const uploadFile = async () => {
        setUploadInProgress(true)
        let file = files[0].file
        const data = {
            tenantId,
            locationId,
            userId: localStorage.getItem("userId"),
            file: file
        }
        let response = await upload(getUploadApiUrl(tenantId), data)
        setUploadInProgress(false)
        if (response) {
            return response.mediaUrl;
        }
    }

    return { handleSubmit, onFileSelect, uploadInProgress, apiInProgress }
}