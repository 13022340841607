import React from "react";
export const toaster = (status, msg, body) => {
  document.getElementById("liveToast").classList.replace("hide", "show");
  document.getElementById("toast-msg-title").innerHTML = msg;

  document.getElementById("toast-msg-body").innerHTML =
    body !== "null" ? `${body.substring(0, 30)}...` : `New request for ${msg}`;

  if (status === "success") {
    document.getElementById("toast-img");
    document.getElementById("toast-msg-title").style.color = "green";
  }
  if (status === "error") {
    document.getElementById("toast-img");
    document.getElementById("toast-msg-body").style.color = "red";
  }
  // setTimeout(() => {
  //     document.getElementById("liveToast").classList.replace("show", "hide")
  // }, 11111)
};
export default function Toast() {
  return (
    <a
      href={`#/t/${localStorage.getItem("tenantId")}/l/${localStorage.getItem(
        "locationId"
      )}/service-requests`}
    >
      <div className="position-fixed top-0 start-100  p-3 alert-msg z-index-1212">
        <div
          id="liveToast"
          className="toast hide w-auto px-4 bg-white text-nowrap"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header flex-column">
            <strong className="me-auto" id="toast-msg-title"></strong>
            <strong className="me-auto" id="toast-msg-body"></strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </a>
  );
}
