import React from "react";
import Card from "../../cards/card";

export default function IconBox({ data, className, onClick }) {

    return (
        <Card className={`text-center border-1 icon-box pointer ${className}`}
        // onClick={() => onClick(data)}
        >
            <span className="d-block text-center">
                {
                    data.imageUrl ? <img className="icon" src={data.imageUrl} alt={data.name} /> : <i className="justify-content-center tf-icons bx bxs-cog"></i>
                }
            </span>
            <span className="nav-link-text ms-1">{data.name}</span>
        </Card>
    )
}