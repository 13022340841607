import {
    getStaffsApiUrl,
    getSaveStaffApiUrl,
    getStaffApiUrl,
    getStaffServicesApiUrl,
    getSaveStaffServicesApiUrl,
    getDeleteStaffApiUrl,
    getUserExistApiUrl,
    getStaffIdByUserId,
    getStaffOnlyCount
} from "./ApiUrls";
import { post, get, put, Delete } from "./Service";


export const getStaffs = async (tenantId, locationId) => {
    const response = await get(getStaffsApiUrl(tenantId, locationId));
    if (!response) {
        return []
    }
    return response;
};

export const getUserExist = async (email) => {
    const response = await get(getUserExistApiUrl(email));
    if (!response) {
        return []
    }
    return response;
};



export const getStaff = async (tenantId, locationId, staffId) => {
    const response = await get(getStaffApiUrl(tenantId, locationId, staffId));
    if (!response) {
        return []
    }
    return response;
};

export const saveStaffServices = async (tenantId, locationId, staffId, data) => {
    const response = await post(getSaveStaffServicesApiUrl(tenantId, locationId, staffId), data);
    return response;
};

export const getStaffServices = async (tenantId, locationId, staffId) => {
    const response = await get(getStaffServicesApiUrl(tenantId, locationId, staffId));
    if (!response) {
        return []
    }
    return response;
};

export const saveStaff = async (tenantId, locationId, data) => {
    const response = await post(getSaveStaffApiUrl(tenantId, locationId), data);
    return response;
};

export const updateStaff = async (tenantId, locationId, data) => {
    const response = await put(getSaveStaffApiUrl(tenantId, locationId), data);
    return response;
};

export const deleteStaff = async (tenantId, locationId, id) => {
    const response = await Delete(getDeleteStaffApiUrl(tenantId, locationId, id));
    return response;
};

export const StaffIdByUserId = async (id) => {
    const response = await get(getStaffIdByUserId(id));
    return response;
};

export const staffOnlyCount = async (id) => {
    const response = await get(getStaffOnlyCount(id));
    return response;
}
