import {
  getServiceRequestApiUrl,
  getUpdateServiceRequestApiUrl,
  getServiceRequestAssignApiUrl,
  getServiceRequestStatusesApiUrl,
  getUpdateServiceRequestStatusApiUrl,
  getServiceRequestDetailApiUrl,
  getAllServiceRequestCountApi,
  getServiceRequestByStatusApiUrl,
  servicePagination,
} from "./ApiUrls";
import { get, put } from "./Service";

export const getServiceRequests = async (tenantId, locationId) => {
  const response = await get(getServiceRequestApiUrl(tenantId, locationId));
  return response;
};

export const updateServiceRequest = async (tenantId, locationId, data) => {
  const response = await put(
    getUpdateServiceRequestApiUrl(tenantId, locationId),
    data
  );
  return response;
};

export const assign = async (tenantId, locationId, data) => {
  const response = await put(
    getServiceRequestAssignApiUrl(tenantId, locationId),
    data
  );
  return response;
};

export const updateStatus = async (tenantId, locationId, data) => {
  const response = await put(
    getUpdateServiceRequestStatusApiUrl(tenantId, locationId),
    data
  );
  return response;
};

export const getServiceRequestStatuses = async (tenantId, locationId) => {
  const response = await get(
    getServiceRequestStatusesApiUrl(tenantId, locationId)
  );
  return response;
};

export const getServiceRequestDetail = async (tenantId, locationId, id) => {
  const response = await get(
    getServiceRequestDetailApiUrl(tenantId, locationId, id)
  );
  return response;
};

export const getAllServiceRequestCount = async (tenantId, locationId, id) => {
  const response = await get(
    getAllServiceRequestCountApi(tenantId, locationId, id)
  );
  return response;
};

export const getServiceRequestByStatus = async (staffId, id) => {
  const response = await get(
    getServiceRequestByStatusApiUrl(staffId, id)
  );
  return response;
};


export const getServicePagination = async (tenantId, locationId,currentPage,size,statusId) => {
  const response = await get(
    servicePagination(tenantId, locationId,currentPage,size,statusId)
  );

  
  return response; 
};