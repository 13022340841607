import React from "react";
import FormPopup from "./form-popup";
import FileSelector from "../inputs/file-selector";
import ProcessLoader from "../general/process-loader";
import {
  nameField,
  descriptionField,
  email_validation,
  phone_validation,
  password_validation,
} from "../../utils/inputValidations";
import { Input } from "../inputs/input";
import { useForm, FormProvider } from "react-hook-form";

export default function NameDescriptionPopup({
  title,
  onSave,
  onCancel,
  formData,
  hook,
  placeHolder,
  addAdmin,
  edit,
  showForm,
  update,
  setUpdate,
  departmentId,
}) {
  const {
    handleInputChange,
    handleSubmit,
    onFileSelect,
    data,
    uploadInProgress,
    apiInProgress,
  } = hook(onSave, formData);

  const nameInputField = nameField(
    "name",
    "name",
    placeHolder + " Name",
    "Enter " + placeHolder.toLowerCase() + " name",
    true,
    3,
    100
  );
  const descriptionInputField = descriptionField(
    "description",
    "description",
    placeHolder + " Description",
    "Enter " + placeHolder.toLowerCase() + " description",
    false,
    255
  );
  const firstName = nameField(
    "firstName",
    "firstName",
    "First Name",
    "Enter first name",
    true,
    3,
    100
  );
  const lastName = nameField(
    "lastName",
    "lastName",
    "Last Name",
    "Enter last name",
    true,
    3,
    100
  );

  const methods = useForm({
    defaultValues: formData
      ? {
          name: formData.name,
          description: formData.description,
        }
      : {},
  });

  const onSubmit = methods.handleSubmit((data) => {
    let dataUpdate = update;
    handleSubmit(data, dataUpdate, departmentId);
    setUpdate && setUpdate(false);
    if (showForm) {
      showForm(false);
    }
  });
  return (
    <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel}>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => e.preventDefault()}
          onChange={(event) => handleInputChange(event)}
          noValidate
          autoComplete="off"
          className="overflow-auto d-flex justify-content-between flex-column h-100 height-name-description-form-popup"
        >
          <Input id="name" {...nameInputField} className="mb-3" />
          <Input
            id="description"
            {...descriptionInputField}
            departmentClass="text-area-min h-auto"
            className="mb-3"
          />
          <FileSelector
            name="attachment"
            existingFiles={data.files}
            onFileSelect={onFileSelect}
            label="Upload icon"
            showProgress={uploadInProgress}
          />
          {addAdmin && (
            <>
              <div className="d-flex gap-2 mt-3">
                <Input id="name" {...firstName} className="mb-2 " />
                <Input id="name" {...lastName} className="mb-2" />
              </div>
              <div className="d-flex gap-2">
                <Input id="name" {...email_validation} className="mb-2 " />
                <Input id="name" {...password_validation} className="mb-2 " showEyeIcon="true" inDiv={"d-flex align-items-center position-relative"} />
              </div>
              <div className="d-flex gap-2">
                <Input id="name" {...phone_validation} className="mb-2" />
              </div>
            </>
          )}
          <div className="form-footer mt-2 d-flex gap-3">
            <button
              type="button"
              className="mb-3 w-100 btn btn-outline-secondary r-30px"
              onClick={() => onCancel(setUpdate && setUpdate(false))}
            >
              Cancel
            </button>
            <button
              className={`edc-btn-secondary mb-3 w-100 `}
              onClick={onSubmit}
            >
              Save
            </button>
          </div>
          {apiInProgress && <ProcessLoader />}
        </form>
      </FormProvider>
    </FormPopup>
  );
}
