import React, { useEffect } from "react";
import FormPopup from "./form-popup";
import useProductForm from "../../hooks/forms/useProductForm";
import FileSelector from "../inputs/file-selector";
import ProcessLoader from "../general/process-loader";
import Select from "../inputs/select";
import {
  nameField,
  descriptionField,
  numberField,
  time_validation,
} from "../../utils/inputValidations";
import { Input } from "../inputs/input";
import { useForm, FormProvider } from "react-hook-form";

export default function ProductFormPopup({
  id,
  title,
  onSave,
  onCancel,
  formData,
  categories,
  departments,
  placeHolder,
  setFormData
}) {
  const {
    handleSubmit,
    onFileSelect,
    uploadInProgress,
    apiInProgress,
    check,
    handleInputChange,
    time,
    convertHoursMinutesToMinutes,
    tat
  } = useProductForm(onSave,setFormData,formData);


  const nameInputField = nameField(
    "name",
    "name",
    placeHolder + " Name",
    "Enter " + placeHolder.toLowerCase() + " name",
    true,
    3,
    100
  );
  const descriptionInputField = descriptionField(
    "description",
    "description",
    placeHolder + " Description",
    "Enter " + placeHolder.toLowerCase() + " description",
    false,
    255
    );
    
    const priceInputField = numberField(
      "price",
      "price",
      placeHolder + " Price",
      "Enter " + placeHolder.toLowerCase() + " price",
      true
      );
      const wasPriceInputField = numberField(
        "wasPrice",
        "wasPrice",
        placeHolder + " Old Price",
        "Enter " + placeHolder.toLowerCase() + " old price",
        true 
        );

  const methods = useForm({
    defaultValues: formData
      ? {
          id: formData.id,
          name: formData.name,
          description: formData.description,
          coverImage: formData.coverImage,
          price: formData.price,
          wasPrice: formData.wasPrice,
          category: formData.category.id,
          turnaroundTime:formData.turnaroundTime,
          stockableSr:formData.stockableSr,
          productQuantity:0
        }
      : {},
  });

  
  const onSubmit = methods.handleSubmit((data) => {    
    handleSubmit(data);
  });

  
  
   useEffect(()=>{
    convertHoursMinutesToMinutes(formData.turnaroundTime)
   },[formData.turnaroundTime])

  return (
    <FormPopup id={id} title={title} onSave={handleSubmit} onCancel={onCancel}>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          onChange={(event) => handleInputChange(event)}
          className="product-form-popup-height"
        >
          <Select
            id="category"
            className="mb-3 col-md-12"
            value={formData.category.id}
            name="category"
            label="Select Category"
            list={categories}
            valueProperty="id"
          />
          <Select
            id="department"
            className="mb-3 col-md-12"
            value={formData.department.id}
            name="department"
            label="Select Department"
            list={departments}
            valueProperty="id"
          />
          <Input {...nameInputField} className="mb-3" />
        
          <Input
            id="time"
            {...time_validation}
            check={time}
            className="mb-3"
            defaultValue={formData.turnaroundTime !== 0? tat:""}
          />
          <Input {...descriptionInputField} className="mb-3" />
          
          <Input {...priceInputField} className="mb-3" />
          <Input {...wasPriceInputField} className="mb-3" /> 
          <input type="checkbox"   checked={check === "Y" || formData.stockableSr === "Y"} name="stockable" onChange={(e) => handleInputChange(e)}/>
          <span> Stockable</span>
          <FileSelector
            name="attachment"
            existingFiles={formData.files}
            onFileSelect={onFileSelect}
            label="Upload icon"
            showProgress={uploadInProgress}
          />
          <div className="form-footer mt-3">
            <button
              className={`edc-btn-secondary mb-3 col-md-12 mt-3`}
              onClick={onSubmit}
            >
              Save
            </button>
            <button
              type="button"
              className="mb-3 col-md-12 btn btn-outline-secondary"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
          </div>
          {apiInProgress && <ProcessLoader />}
        </form>
      </FormProvider>
    </FormPopup>
  );
}
