import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import Layout from "../components/layout/layout";
import useCategories from "../hooks/useCategories";
import Popover from "../components/popups/popover";
import Toast from "../components/general/toast";
import CategoryFormPopup from "../components/popups/category-form-popup";
import Avatar from "../components/general/avatar";
import DataTable from "react-data-table-component";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";

export default function Categories() {
  const {
    showForm,
    list,
    formData,
    handleSubmit,
    loading,
    handleAddClick,
    handleCancelClick,
    types,
    handleDeleteClick,
    handleEditClick,
    showMessage,
    showConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    message,
    selectedItem,
    handleRowClick,
    setEdit,
  } = useCategories();
  const columns = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}>
          {" "}
          {index + 1}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Name",
      id: "tableHeader",
      selector: (row) => row.name,
      sortable: false,
      cell: (row) => (
        <span
          className={`avatar-container capitalize ${row.deleted ? "text-danger" : ""}`}
          onClick={() => handleRowClick(row)}
        >
          <Avatar image={row.imageUrl} name={row.name} />{" "}
          <strong>{row.name}</strong>
        </span>
      ),
    },
    {
      name: "Type",
      id: "tableHeader",
      selector: (row) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}
        >
          {" "}
          {row.type}
        </span>
      ),
      sortable: false,
    },
    {
      name: "Description",
      id: "tableHeader",
      selector: (row) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}
        >
          {" "}
          {row.description}
        </span>
      ),
      sortable: false,
    },
    {
      name: "Edit",
      id: "tableHeader",
      selector: (row) =>
        !row.deleted && (
          <EditButton
            onClick={(event) => {
              handleEditClick(event, row);
              setEdit(true);
            }}
          />
        ),
      width: "80px",
      center: "true",
    },
    {
      name: "Delete",
      id: "tableHeader",
      selector: (row) =>
        !row.deleted && (
          <DeleteButton onClick={(event) => handleDeleteClick(event, row)} />
        ),
      width: "80px",
      center: "true",
    },
  ];

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card
            title="Categories manager"
            subTitle="Add/Edit/Delete your categories."
            modalId="masterTypeModal"
            showAddButton
            onAdd={handleAddClick}
          >
            <DataTable
              data={list}
              columns={columns}
              pagination
              className="dataTable"
              onRowClicked={handleRowClick}
            />
          </Card>
          {showForm && (
            <CategoryFormPopup
              placeHolder="Category"
              onCancel={handleCancelClick}
              onSave={handleSubmit}
              formData={formData}
              title="Category"
              types={types}
            />
          )}
        </>
      )}
      {showConfirmation && (
        <Popover
          title={`Confirm deleting '${selectedItem.name}'`}
          showActions
          saveLabel="Delete"
          onCancel={onDeleteCancel}
          onClose={onDeleteCancel}
          onSave={onDeleteConfirm}
        >
          <p className="mt-1">{`Are you sure that you want to delete the category '${selectedItem.name}'?`}</p>
        </Popover>
      )}

      {showMessage && <Toast text={message} className="success" />}
    </Layout>
  );
}
