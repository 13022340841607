import React from "react";
import FormPopup from "./form-popup";
import useServiceForm from "../../hooks/forms/useServiceForm";
import FileSelector from "../inputs/file-selector";
import ProcessLoader from "../general/process-loader";
import Select from "../inputs/select"; // eslint-disable-next-line
import {
  nameField,
  descriptionField,
  time_validation,
} from "../../utils/inputValidations";
import { Input } from "../inputs/input";
import { useForm, FormProvider } from "react-hook-form";

export default function ServiceFormPopup({
  id,
  title,
  onSave,
  onCancel,
  formData,
  categories,
  placeHolder,
}) {
  // eslint-disable-next-line
  const {
    handleInputChange,
    handleSubmit,
    onFileSelect,
    data,
    uploadInProgress,
    apiInProgress,
    check,
  } = useServiceForm(id, onSave, formData);
  const nameInputField = nameField(
    "name",
    "name",
    placeHolder + " Name",
    "Enter " + placeHolder.toLowerCase() + " name",
    true,
    3,
    100
  );
  const descriptionInputField = descriptionField(
    "description",
    "description",
    placeHolder + " Description",
    "Enter " + placeHolder.toLowerCase() + " description",
    false,
    255
  );

  const methods = useForm({
    defaultValues: formData
      ? {
          name: formData.name,
          description: formData.description,
          time: formData.tat ? formData.tat : "",
        }
      : {},
  });

  const onSubmit = methods.handleSubmit((data) => {
    handleSubmit(data);
  });
  return (
    <FormPopup id={id} title={title} onSave={handleSubmit} onCancel={onCancel}>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => e.preventDefault()}
          onChange={(event) => handleInputChange(event)}
          noValidate
          autoComplete="off"
        >
          <Select
            id="category"
            className="mb-3 col-md-12"
            value={formData.category.id}
            name="category"
            label="Select Category"
            list={categories}
            valueProperty="id"
            onChange={handleInputChange}
          />
          <Input id="name" {...nameInputField} className="mb-3" />
          <Input
            id="time"
            {...time_validation}
            check={check}
            className="mb-3"
          />
          <Input id="description" {...descriptionInputField} className="mb-3" />
          <FileSelector
            name="attachment"
            existingFiles={data.files}
            onFileSelect={onFileSelect}
            label="Upload icon"
            showProgress={uploadInProgress}
          />
          <div className="form-footer mt-3">
            <button
              className={`edc-btn-secondary mb-3 col-md-12 mt-3`}
              onClick={onSubmit}
            >
              Save
            </button>
            <button
              type="button"
              className="mb-3 col-md-12 btn btn-outline-secondary"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
          </div>
          {apiInProgress && <ProcessLoader />}
        </form>
      </FormProvider>
    </FormPopup>
  );
}
