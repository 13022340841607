import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getProducts } from "../service/ProductService";
import { getCategoriesByType } from "../service/CategoryService";
import { getDepartments, getProductsByDepartmentId } from "../service/DepartmentService";
import { saveQuantity } from "../service/StockService";

export default function useStock() {
  const [list, setList] = useState([]);
  const { tenantId, locationId } = useParams();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [stockDetails,setStockDetails] = useState([])
  const handleInputChange = (e, id, categoryId, departmentId, name, description) => {
    setStockDetails((prevValues) => {
      const rowIndex = prevValues.findIndex((item) => item.id === id);
  
      if (rowIndex !== -1) {
        return prevValues.map((item, index) =>
          index === rowIndex
            ? {
                ...item,
                value: e.target.value,
                name: name,
                description: description,
                categoryId: categoryId,
                departmentId: departmentId,
                id:id
              }
            : item
        );
      }
  
      return [
        ...prevValues,
        {
          id: id,
          value: e.target.value,
          name: name,
          description: description,
          categoryId: categoryId,
          departmentId: departmentId,
        },
      ];
    });
  };
  
  
  const fetchAll = async () => {
    const response = await getProducts(tenantId, locationId);
    const newData = response.filter((item)=>item.stockableSr === "Y" && item.deleted === false)
    
    setList(newData);
    setLoading(false);

  };
  

  const fetchProductsBasedOnDepartmentId = async(departmentId)=>{
    
     getProductsByDepartmentId(tenantId,locationId,departmentId).then((res)=>{      
       const filtered = res.filter((item,index)=>item.deleted === false && item.stockableSr === "Y")
       setList(filtered)
     })
     
  }
  const fetchCategories = async () => {
    const response = await getCategoriesByType(tenantId, locationId, "PRODUCT");
    const categories = response.filter((item) => item.deleted === false);
    setCategories(categories);
  };

  const fetchDepartments = async () => {
    const response = await getDepartments(tenantId, locationId);
    const departments = response.filter((item) => item.deleted === false);
    setDepartments(departments);
  };

  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

 

  const saveQuantityForProduct  = async()=>{
    const data = {
      stockdetails: stockDetails.map(item => ({
        productQuantity: item.value,
        name: item.name,
        description: item.description,
        category:{
          id:item.categoryId,
        } ,
        department:{
          id:item.departmentId
        },
        product:{
          id:item.id
        } ,
      })),
    };
     saveQuantity(tenantId,locationId,data.stockdetails).then((res)=>{
      
      if(res){
        displayMessage(
          "Stock added successfully"
        );
        fetchProductsBasedOnDepartmentId( res[0].department && res[0].department.id)
        
        setStockDetails([])
       }
     })
     
     
  }
  useEffect(() => {
    fetchAll();
    fetchCategories();
    fetchDepartments();
  }, [locationId]);

  return {
    list,
    loading,
    categories,
    departments,
    showMessage,
    message,
    saveQuantityForProduct,
    stockDetails,
    handleInputChange,
    fetchProductsBasedOnDepartmentId
  };
}
