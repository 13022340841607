import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import Layout from "../components/layout/layout";
import Avatar from "../components/general/avatar";
import DataTable from "react-data-table-component";
import useStock from "../hooks/useStock";
import Toast from "../components/general/toast";

export default function Stock() {
  const {
    list,
    loading,
    handleInputChange,
    stockDetails,
    saveQuantityForProduct,
    showMessage,
    message,
    departments,
    fetchProductsBasedOnDepartmentId
  } = useStock();


 
  
  const columns = [
    {
      name: "S. No",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {index + 1}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      minWidth: "150px",

      sortable: false,
      cell: (row) => (
        <span className="avatar-container capitalize">
          <Avatar image={row.coverImage} name={row.name} />{" "}
          <strong>{row.name}</strong>
        </span>
      ),
    },
    {
      name: "Category",
      selector: (row) => (
        <span className={`capitalize ${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.category ? row.category.name : ""}
        </span>
      ),
      sortable: false,
    },
    {
      name: "Department",
      selector: (row) => (
        <span className={`capitalize ${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.department ? row.department.name : ""}
        </span>
      ),
      sortable: false,
    },
    {
      name: "Price",
      // selector: (row) => row.price,
      selector: (row) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.price ? row.price : ""}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Old Price",
      selector: (row) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.wasPrice ? row.wasPrice : ""}
        </span>
      ),
      center: "true",
    },
    {
      name: "Existing Qty",
      selector: (row) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.productQuantity}
        </span>
      ),
      center: "true",
    },
    {
      name: "New Qty",
      selector: (row) => {
        const foundItem = stockDetails.find((item) => item.id === row.id);
        const value = foundItem ? foundItem.value : ""; 
    
        

        
        return (
          <input
            type="text"
            pattern="[0-9]*"
            className="w-100 form-control"
            onChange={(e) =>{ 
              const inputValue = e.target.value;
        
        if (/^[0-9]*$/.test(inputValue)) {
      
          handleInputChange(e, row.id, row.category.id, row.department.id, row.name, row.description);
        } else {
          e.preventDefault();
          console.error("Please enter a positive number");
        }
      }}
            value={value}
          />
        );
      },
      center: "true",
    },
   
  ];


  
  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card
            title="Stock Management"
            subTitle="Add/Edit/Delete your stock."
            modalId="masterTypeModal"
            showAddButton
            onAdd={saveQuantityForProduct}
          >
            <h6 className="">Select Department :</h6>
                <select
                  name="statuses"
                  className="form-select w-md-25 w-25  rounded-5 border border-grey"
                  onChange={(e) => {
                    fetchProductsBasedOnDepartmentId(e.target.value)                  }}
                >
                    <option>
                        All
                      </option>
                  {departments.map((item, i) => 
                     (
                      <>
                    
                      <option value={item.id} key={i} >
                        {item.name}
                      </option>
                      </>
                    )
                  )}

                </select>
            <DataTable data={list} columns={columns} pagination />
           
          </Card>
          {showMessage && <Toast text={message} className="success" />}

        </>
      )}
   

    </Layout>
  );
}
