import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import Layout from "../components/layout/layout";
import ServiceFormPopup from "../components/popups/service-form-popup";
import useServices from "../hooks/useServices";
import Popover from "../components/popups/popover";
import Toast from "../components/general/toast";
import Avatar from "../components/general/avatar";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";
import DataTable from "react-data-table-component";

export default function Services() {
  const {
    showForm,
    formData,
    handleSubmit,
    list,
    loading,
    handleAddClick,
    handleCancelClick,
    categories,
    handleDeleteClick,
    handleEditClick,
    showMessage,
    showConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    message,
    selectedItem,
    setEdit,
  } = useServices();

  const columns = [
    {
      name: "S. No",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {index + 1}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      // sortable: true,
      width: "700px",
      cell: (row) => (
        <span
          className={`avatar-container text-truncate w-100 capitalize ${row.deleted ? "text-danger" : ""
            }`}
        >
          <Avatar image={row.imageUrl} name={row.name} />
          <span className="">
            <strong>{row.name}</strong>
            <p className="description">{row.description}</p>
          </span>
        </span>
      ),
    },
    {
      name: "Category",
      selector: (row) => (
        <span className={`capitalize ${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {row.category.name}
        </span>
      ),
      sortable: false,
    },
    {
      name: "Edit",
      selector: (row) =>
        !row.deleted && (
          <EditButton
            onClick={(event) => {
              handleEditClick(event, row);
              setEdit(true);
            }}
          />
        ),
      width: "80px",
      center: "true",
    },
    {
      name: "Delete",
      selector: (row) =>
        !row.deleted && (
          <DeleteButton onClick={(event) => handleDeleteClick(event, row)} />
        ),
      width: "80px",
      center: "true",
    },
  ];

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card
            title="Services"
            subTitle="Add/Edit/Delete services."
            showAddButton
            onAdd={handleAddClick}
          >
            {/* <div className="row">
                    {list && list.length > 0 ? <div className="table-responsive text-nowrap h100 w-100">
                    <table className="table striped">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Name</th>
                                <th>Category</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list && list.map((item , index) => 
                            <tr key={item.id} className={item.deleted ? 'danger' : ''}>
                                <td>{index+1}</td>
                                <td>
                                    <span className="avatar-container">
                                        <Avatar image={item.imageUrl} name={item.name} />
                                        <span>
                                            <strong>{item.name}</strong>
                                            <p className="description">{item.description}</p>
                                        </span>
                                    </span>
                                </td>
                                <td>{item.category ? item.category.name : ""}</td>
                                <td className="align-right">{!item.deleted && <TableActions data={item} onDelete={handleDeleteClick} onEdit={handleEditClick} /> }</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div> : <NoDataMessage showAddButton onAdd={handleAddClick}/>}
                </div> */}
            <DataTable data={list} columns={columns} pagination />
          </Card>
          {showForm && (
            <ServiceFormPopup
              placeHolder="Service"
              onCancel={handleCancelClick}
              onSave={handleSubmit}
              formData={formData}
              title="Service"
              categories={categories}
            />
          )}
        </>
      )}
      {showConfirmation && (
        <Popover
          title={`Confirm deleting '${selectedItem.name}'`}
          showActions
          saveLabel="Delete"
          onCancel={onDeleteCancel}
          onClose={onDeleteCancel}
          onSave={onDeleteConfirm}
        >
          <p className="mt-1">{`Are you sure that you want to delete the service '${selectedItem.name}'?`}</p>
        </Popover>
      )}

      {showMessage && <Toast text={message} className="success" />}
    </Layout>
  );
}
