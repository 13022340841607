import React from "react";
import Layout from "../components/layout/layout";
import useDepartments from "../hooks/useDepartments";
import Loader from "../components/general/loader";
import Popover from "../components/popups/popover";
import Toast from "../components/general/toast";
import Card from "../components/cards/card";
import NoDataMessage from "../components/general/NoDataMessage";
import useDepartmentForm from "../hooks/forms/useDepartmentForm";
import NameDescriptionPopup from "../components/popups/name-description-form-popup";
import Avatar from "../components/general/avatar";
import DataTable from "react-data-table-component";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";

export default function Departments() {
    const {
        showForm,
        list,
        formData,
        handleSubmit,
        action,
        loading,
        handleAddClick,
        handleEditClick,
        handleDeleteClick,
        handleCancelClick,
        showMessage,
        showConfirmation,
        onDeleteConfirm,
        onDeleteCancel,
        handleRowClick,
        message,
        selectedItem,
        setEdit,
        addAdmin,
        setAddAdmin,
        update,
        setUpdate, departmentId
    } = useDepartments();
    const columns = [
        {
            name: "S. No",
            id: "tableHeader",
            cell: (row, index) => (
                <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}>
                    {" "}
                    {index + 1}
                </span>
            ),
            width: "80px",
            center: "true",
        },
        {
            name: "Name",
            id: "tableHeader",
            selector: (row) => row.name,
            sortable: false,
            cell: (row) => (
                <span
                    className={`avatar-container capitalize ${row.deleted ? "text-danger" : ""}`}
                    onClick={() => handleRowClick(row)}
                >
                    <Avatar image={row.imageUrl} name={row.name} />{" "}
                    <strong>{row.name}</strong>
                </span>
            ),
        },
        {
            name: "Description",
            id: "tableHeader",
            selector: (row) => (
                <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}>
                    {" "}
                    {row.description}
                </span>
            ),
            sortable: false,
        },
        {
            name: "Edit",
            id: "tableHeader",
            selector: (row) =>
                !row.deleted && (
                    <EditButton
                        onClick={(event) => {
                            handleEditClick(event, row);
                            setAddAdmin(false);
                            setEdit(true);
                        }}
                    />
                ),
            width: "80px",
            center: "true",
        },
        {
            name: "Delete",
            id: "tableHeader",
            selector: (row) =>
                !row.deleted && (
                    <DeleteButton onClick={(event) => handleDeleteClick(event, row)} />
                ),
            width: "80px",
            center: "true",
        },
    ];
    return (
        <Layout>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Card
                        title="Departments manager"
                        subTitle="Add/Edit/Delete your departments."
                        showAddButton
                        onAdd={handleAddClick}
                    >
                        {list && list.length > 0 ? (
                            <DataTable
                                data={list}
                                columns={columns}
                                pagination
                                className="dataTable"
                                onRowClicked={handleRowClick}
                            />
                        ) : (
                            <NoDataMessage showAddButton onAdd={handleAddClick} />
                        )}
                    </Card>
                    {showForm && (
                        <NameDescriptionPopup
                            placeHolder="Department"
                            hook={useDepartmentForm}
                            addAdmin={addAdmin}
                            // edit={editForm}
                            action={action}
                            onCancel={handleCancelClick}
                            onSave={handleSubmit}
                            formData={formData}
                            title="Department"
                            update={update}
                            setUpdate={setUpdate}
                            departmentId={departmentId}
                        />
                    )}
                    {showConfirmation && (
                        <Popover
                            title={`Confirm deleting '${selectedItem.name}'`}
                            showActions
                            saveLabel="Delete"
                            onCancel={onDeleteCancel}
                            onClose={onDeleteCancel}
                            onSave={onDeleteConfirm}
                        >
                            <p className="mt-1">{`Are you sure that you want to delete the department '${selectedItem.name}'?`}</p>
                        </Popover>
                    )}
                </>
            )}
            {showMessage && <Toast text={message} className="success" />}
        </Layout>
    );
}
