import React, { useState } from "react";
import { findInputError, isFormInvalid } from "../../utils";
import { useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { MdError } from "react-icons/md";

export const Input = ({
  name,
  label,
  type,
  id,
  placeholder,
  validation,
  className,
  multiline,
  disabled = false,
  showMessage = false,
  message,
  departmentClass,
  showEyeIcon,
  inDiv,
  defaultValue,
  check,
  step,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={className ? className : ""}>
      <div className="flex justify-between">
        <label htmlFor={id} className="font-semibold capitalize">
          {label}
          {check !== "" ? (
            <span className="text-danger fw-normal text-end fs-6">
              {check}{" "}
            </span>
          ) : (
            ""
          )}
        </label>
        <AnimatePresence mode="wait" initial={false}>
          {isInvalid && (
            <InputError
              message={inputErrors.error.message}
              key={inputErrors.error.message}
            />
          )}
          {showMessage && <InputError message={message} key="message" />}
        </AnimatePresence>
      </div>
      {!multiline ? (
        <div className={inDiv}>
          <input
            step={step}
            defaultValue={defaultValue ? defaultValue : undefined}
            id={id}
            // type={type}
            type={showPassword ? (showPassword ? "text" : "password") : type}
            className="form-control"
            placeholder={placeholder}
            {...register(name, validation)}
            disabled={disabled}
          />
          {showEyeIcon && (
            <i
              className={`fa ${
                showPassword ? "fa-eye" : "fa-eye-slash"
              } position-absolute right-8px`}
              aria-hidden="true"
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer" }}
            ></i>
          )}
        </div>
      ) : (
        <textarea
          id={id}
          type={type}
          className={`form-control h-25 ${departmentClass}`}
          placeholder={placeholder}
          {...register(name, validation)}
        />
      )}
    </div>
  );
};

const InputError = ({ message }) => {
  return (
    <motion.p className="error-message" {...framer_error}>
      <MdError />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
