import React from "react";
export default function StatusSelectGroup({
  className,
  enabled = true,
  label,
  name,
  statusvalue,
  type,
  onChange,
  placeHolder,
  list,
  valueProperty,
  showLabel = true,
  labelProperty = "name",
}) {
  // for closed you have to change the slice to(1,5)
  return (
    <div
      className={className + " w-100 rounded-3"}
      onClick={(event) => event.stopPropagation()}
    >
      {showLabel && <label className="form-label">{label}</label>}
      <select
        onClick={(event) => event.stopPropagation()}
        className="w-100  form-select rounded-3"
        disabled={!enabled}
        name={name}
        onChange={(event) => onChange(event)}
        type={type}
        value={statusvalue}
        placeholder={placeHolder}
      >
        {list[0].id !== "" &&
          statusvalue === list[0].id &&
          list.slice(0, 2).map((item) => (
            <option key={item[valueProperty]} value={item[valueProperty]}>
              {item[labelProperty]}
            </option>
          ))}
        {list[1].id !== "" &&
          statusvalue === list[1].id &&
          list.slice(1, 4).map((item) => (
            <option key={item[valueProperty]} value={item[valueProperty]}>
              {item[labelProperty]}
            </option>
          ))}
        {list[2].id !== "" &&
          statusvalue === list[2].id &&
          list.slice(2, 4).map((item) => (
            <option key={item[valueProperty]} value={item[valueProperty]}>
              {item[labelProperty]}
            </option>
          ))}
        {list[3].id !== "" &&
          statusvalue === list[3].id &&
          list.slice(3, 4).map((item) => (
            <option key={item[valueProperty]} value={item[valueProperty]}>
              {item[labelProperty]}
            </option>
          ))}
        {list[4].id !== "" &&
          statusvalue === list[4].id &&
          list.slice(4, 4).map((item) => (
            <option key={item[valueProperty]} value={item[valueProperty]}>
              {item[labelProperty]}
            </option>
          ))}
        {(statusvalue === "" ||
          statusvalue === null ||
          statusvalue === undefined) &&
          list.map((item) => (
            <option key={item[valueProperty]} value={item[valueProperty]}>
              {item[labelProperty]}
            </option>
          ))}
      </select>
    </div>
  );
}
