import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getServices, deleteService } from "../service/ServiceService";
import { getCategoriesByType } from "../service/CategoryService";

export default function useServices() {
  const [list, setList] = useState([]);

  const [edit, setEdit] = useState(false);

  const { tenantId, locationId, categoryId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    imageUrl: undefined,
    deleted: false,
    tenantId,
    locationId,
    tat: "",
    category: { id: categoryId },
  });

  const [loading, setLoading] = useState(true);

  const [showForm, setShowForm] = useState(false);

  const [categories, setCategories] = useState([]);

  const [selectedItem, setSelectedItem] = useState();

  const [showMessage, setShowMessage] = useState(false);

  const [message, setMessage] = useState("");

  const [showConfirmation, setShowConfirmation] = useState(false);

  const getHeaders = () => {
    const headers = [
      { title: "Name", type: "label", property: "name" },
      { title: "Description", type: "label", property: "description" },
      { title: "Action", type: "action" },
    ];
    return headers;
  };

  const handleSubmit = async (response) => {
    setShowForm(false);
    fetchAll();
    displayMessage(
      `Service '${response.name}' has been ${
        edit ? "updated" : "saved"
      } successfully.`
    );
    setEdit(false);
  };

  const fetchAll = async () => {
    const response = await getServices(tenantId, locationId);
    setList(response);
    setLoading(false);
  };

  const fetchCategories = async () => {
    const response = await getCategoriesByType(tenantId, locationId, "SERVICE");
    const categories = response.filter((item) => item.deleted === false);
    setCategories(categories);
  };

  const handleAddClick = () => {
    setEdit(false);
    setFormData({
      category: {},
      name: "",
      description: "",
      locationId: locationId,
    });
    setShowForm(true);
  };

  const handleCancelClick = () => {
    setShowForm(false);
  };

  const handleEditClick = (event, row) => {
    event.stopPropagation();
    if (!row.category) {
      row.category = {};
    }
    if (row.imageUrl) {
      row.files = [{ image: row.imageUrl, name: "GJ Concierge" }];
    } else {
      row.files = [];
    }
    setFormData(row);
    setShowForm(true);
  };

  const handleDeleteClick = (event, row) => {
    event.stopPropagation();
    setSelectedItem(row);
    setShowConfirmation(true);
  };

  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const onDeleteCancel = () => {
    setShowConfirmation(false);
  };

  const onDeleteConfirm = async () => {
    await deleteService(tenantId, locationId, selectedItem.id);
    fetchAll();
    setShowConfirmation(false);
    displayMessage(
      `Service '${selectedItem.name}' has been deleted successfully.`
    );
  };

  useEffect(() => {
    fetchAll();
    fetchCategories();
  }, [locationId]);

  return {
    showForm,
    list,
    formData,
    getHeaders,
    handleSubmit,
    loading,
    handleAddClick,
    handleDeleteClick,
    handleCancelClick,
    categories,
    handleEditClick,
    showMessage,
    showConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    message,
    selectedItem,
    setEdit,
  };
}
