import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { getFloors, deleteFloor } from "../service/FloorService"
import { getFloorDetailUrl } from '../utils/Constant'

export default function useFloors() {

    const modalId = "FloorModal"

    const [list, setList] = useState([])

    const [formData, setFormData] = useState(undefined)

    const [loading, setLoading] = useState(true)

    const { tenantId, locationId, blockId, name } = useParams()

    const [showForm, setShowForm] = useState(false)

    const navigate = useNavigate()

    const [selectedItem, setSelectedItem] = useState()

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState("")

    const [showConfirmation, setShowConfirmation] = useState(false)

    const getHeaders = () => {
        const headers = [
            {title: "Name", type: "label", property: "name"},
            {title: "Description", type: "label", property: "description"},
            {title: "Action", type: "action"}
        ]
        return headers;
    }

    const handleSubmit = async (response) => {
        setShowForm(false)
        fetchAll()
        displayMessage(`Floor '${response.name}' has been saved successfully.`)

    }

    const fetchAll = async () => {
        const response = await getFloors(tenantId, locationId, blockId)
        setList(response)
        setLoading(false)
    }

    const handleRowClick = (data) => {
        navigate(getFloorDetailUrl(tenantId, locationId, data.id))
    }

    const handleAddClick = () => {
        setFormData(undefined)
        setShowForm(true)
    }

    const handleCancelClick = () => {
        setShowForm(false)
    }

    const handleEditClick = (event, row) => {
        event.stopPropagation()
        if(row.imageUrl) {
            row.files = [{image: row.imageUrl, name:"GJ Concierge"}]
        }else {
            row.files = []
        }
        setFormData(row)
        setShowForm(true)
    }

    const handleDeleteClick = (event, row) => {
        event.stopPropagation()
        setSelectedItem(row)
        setShowConfirmation(true)
    }

    const displayMessage = (message) => {
        setMessage(message)
        setShowMessage(true)
        const timer = setTimeout(() => {
            setShowMessage(false)
          }, 2000);
          return () => clearTimeout(timer);
    }

    const onDeleteCancel = () => {
        setShowConfirmation(false)
    }

    const onDeleteConfirm = async () => {
        await deleteFloor(tenantId, locationId, selectedItem.id)
        fetchAll()
        setShowConfirmation(false)
        displayMessage(`Floor '${selectedItem.name}' has been deleted successfully.`)
    }

    useEffect(() => {
        fetchAll()
    }, [locationId])

    return {showForm, modalId, list, formData, getHeaders, handleSubmit, loading, handleAddClick, 
        handleRowClick,
        handleEditClick, 
        handleDeleteClick, 
        handleCancelClick,
        showMessage, 
        showConfirmation, 
        onDeleteConfirm,
        onDeleteCancel, 
        message,
        selectedItem,
        name
    }

}