import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  saveDepartmentServices,
  getDepartmentServices,
  getDepartmentUser,
  getProductsByDepartmentId,
} from "../service/DepartmentService";
import { getStaffDetailUrl } from "../utils/Constant";
import { StaffIdByUserId } from "../service/StaffService";

export default function useDepartmentDetail() {
  const [services, setServices] = useState([]);

  const { tenantId, locationId, departmentId, name } = useParams();

  const [loading, setLoading] = useState(true);

  const [enable, setEnable] = useState(false);

  const [list, setList] = useState([]);

  const [deptUser, setDeptUser] = useState(false);

  const [showMessage, setShowMessage] = useState(false);

  const [message, setMessage] = useState("");

  const [deptProducts,setDeptProducts] = useState()

  const navigate = useNavigate();

  const onSaveServices = async () => {
    setLoading(true);
    await saveDepartmentServices(tenantId, locationId, departmentId, services);
    setLoading(false);
  };

  const fetchServices = async () => {
    const response = await getDepartmentServices(
      tenantId,
      locationId,
      departmentId
    );
    let filteredReponse =response && response.filter(
      (item) => item.service.deleted === false
    );
    setServices(filteredReponse);
    setLoading(false);
  };

  const toggleSelection = (data, deleted) => {
    data.deleted = deleted;
    data.departmentId = localStorage.getItem("departmentId");
    setServices([...services]);
  };

  useEffect(() => {
    fetchServices();
    fetchDepartmentUser();
    fetchDepartmentProducts()
  }, [locationId]);

  const handleClickDepartmentSection = (event, section) => {
    if (section === "Department") {
      setEnable(false);
    } else {
      setEnable(true);
    }
  };

  const fetchDepartmentUser = async () => {
    const response = await getDepartmentUser(
      tenantId,
      locationId,
      departmentId
    );
    setList(response.user);
    setLoading(false);
  };

  const fetchDepartmentProducts= async() =>{
    const response = await getProductsByDepartmentId(tenantId,locationId,departmentId);
    setDeptProducts(response)    
  }

  
  const handleRowClick = (data) => {
    if (data.deleted) {
      return;
    }
    fetchStaffId(data.id)
  };

  const handleAddDepartmentUser = () => {
    setDeptUser(true);
  };

  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };
  const handleSubmit = async (response) => {
    setDeptUser(false);
    displayMessage(
      `Department '${response.firstName}' has been saved successfully.`
    );
    fetchDepartmentUser();
  };
  const handleCancelClick = () => {
    setDeptUser(false);
  };
  const fetchStaffId = async (id) => {
    const response = await StaffIdByUserId(id)
    navigate(getStaffDetailUrl(tenantId, locationId, response.id));

  }


  return {
    services,
    loading,
    toggleSelection,
    onSaveServices,
    name,
    handleClickDepartmentSection,
    enable,
    list,
    handleAddDepartmentUser,
    deptUser,
    departmentId,
    handleSubmit,
    showMessage,
    message,
    handleCancelClick,
    handleRowClick,
    deptProducts
  };
}
