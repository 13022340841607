import { useState } from "react";
import { useParams } from "react-router-dom";
import { saveProduct, updateProduct } from "../../service/ProductService";
import { getUploadApiUrl } from "../../service/ApiUrls";
import { upload } from "../../service/Service";

export default function useProductForm(onSave,setFormData,formData) {
  const { tenantId, locationId } = useParams();

  const [files, setFiles] = useState([]);

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [apiInProgress, setApiInProgress] = useState(false);

  const [time, setTime] = useState(" 00hr : 00min");
  
  const [check,setCheck] = useState("N")

  const [tat,setTat] = useState()

  const onFileSelect = (files) => {
    setFiles(files);
  };

  function convertTimeToHoursMinutes(timeString) {
    var hours = Math.floor(timeString / 60);
    var remainingMinutes = timeString % 60;
    return " " + hours + "hr :" + ("0" + remainingMinutes).slice(-2) + "min";
  }
 
  function convertHoursMinutesToMinutes(timeString) {
    if(timeString!==0){
      const regex = /(\d+)\s*hr\s*:(\d+)\s*min/;
    const match = timeString.match(regex);
  
    if (!match) {
      return null;
    }
  
    const hours = parseInt(match[1], 10) || 0;
    const minutes = parseInt(match[2], 10) || 0;
  
    const totalMinutes = hours * 60 + minutes;
    setTime(timeString)
    setTat(totalMinutes) ;
    }
    
  }
  const handleInputChange = (event) => {
    
    if (event.target.name === "time") {
      let time = convertTimeToHoursMinutes(event.target.value);
      setTime(time);
    }else if(event.target.name === "stockable"){
       const value = event.target.checked ? "Y" : "N";
       setCheck(value);
       setFormData({...formData,stockableSr:value})
    }
  }; 
  
 
  const handleSubmit = async (data) => {
    setApiInProgress(true);
    
    let payload = {
      id: data.id,
      name: data.name,
      description: data.description,
      category: { id: data.category },
      department: { id: data.department },
      locationId,
      coverImage: data.coverImage,
      price: parseFloat(data.price),
      wasPrice: data.wasPrice,
      turnaroundTime:time,
      stockableSr:check,
      productQuantity:0
    };

    if (files && files.length > 0) {
      payload.coverImage = await uploadFile();
    }

    if (payload.id) {
      await updateProduct(tenantId, locationId, payload);
    } else {
      await saveProduct(tenantId, locationId, payload);
    }
    setApiInProgress(false);
    if (onSave) {
      onSave(data);
    }
  };

  const uploadFile = async () => {
    setUploadInProgress(true);
    let file = files[0].file;
    const data = {
      tenantId,
      locationId,
      userId: localStorage.getItem("userId"),
      file: file,
    };
    let response = await upload(getUploadApiUrl(tenantId), data);
    setUploadInProgress(false);
    if (response) {
      return response.mediaUrl;
    }
  };

  return { handleSubmit, onFileSelect, uploadInProgress, apiInProgress,check,handleInputChange ,time,convertHoursMinutesToMinutes,tat};
}
