import { 
    getFloorsApiUrl,
    getSaveFloorApiUrl,
    getFloorApiUrl,
    getDeleteFloorApiUrl
 } from "./ApiUrls";
import { post, get, put, Delete } from "./Service";


export const getFloors = async (tenantId, locationId, blockId) => {
    const response = await get(getFloorsApiUrl(tenantId, locationId, blockId));
    if(!response) {
        return []
    }
    return response;
};


export const getFloor = async (tenantId, locationId, id) => {
    const response = await get(getFloorApiUrl(tenantId, locationId, id));
    if(!response) {
        return []
    }
    return response;
};

export const saveFloor = async (tenantId, locationId, data) => {
    const response = await post(getSaveFloorApiUrl(tenantId, locationId), data);
    return response;
};

export const updateFloor = async (tenantId, locationId, data) => {
    const response = await put(getSaveFloorApiUrl(tenantId, locationId), data);
    return response;
};

export const deleteFloor = async (tenantId, locationId, id) => {
    const response = await Delete(getDeleteFloorApiUrl(tenantId, locationId, id));
    return response;
};