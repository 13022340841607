import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VDiv from "../containers/vdiv";

import { getStaffs } from "../../service/StaffService";

import Loader from "../general/loader";
import HDiv from "../containers/hdiv";
import Popover from "../popups/popover";
import { getFormattedDateAndTime } from "../../utils/formatters";
import ProcessLoader from "../general/process-loader";
import { getProductRequestDetaillUrl } from "../../utils/Constant";
import Avatar from "../general/avatar";
import DataTable from "react-data-table-component";
import StatusSelectGroup from "../inputs/status-elect-group";
import {
  assign,
  getProductPagination,
  getProductRequestStatuses,
  getProductRequsts,
  updateStatus,
} from "../../service/ProductRequestService";
import ReactPaginate from "react-paginate";
// import ReactPaginate from "react-paginate";

export default function ProductRequestsSection({ alert, filteredStatus }) {
  const { tenantId, locationId } = useParams();

  // const [list, setList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [staffs, setStaffs] = useState([]);

  const [showAssignForm, setShowAssignForm] = useState(false);

  const [statusUpdate, setStatusUpdate] = useState(false);

  const [selectedRequest, setSelectedRequest] = useState();

  let [statuses, setStatuses] = useState([]);

  const [apiInProgress, setApiInProgress] = useState(false);

  // const [pagination,setPagination] = useState()
  const navigate = useNavigate();

  

  const fetchServiceRequests = async () => {
    setLoading(true);
    getProductRequsts(tenantId, locationId).then((response) => {
    //   setList(
    //     filteredStatus
    //       ? response &&
    //           response.filter(
    //             (item) =>
    //               item.status &&
    //               item.status.name &&
    //               item.status.name === filteredStatus[0]
    //           )
    //       : response
    //   );
    });

    fetchStaffs();
  };


  const fetchServiceRequestStatuses = async () => {
    const response = await getProductRequestStatuses(tenantId, locationId);
    setStatuses(response);
    setLoading(false);
  };

  const goToDetail = (item) => {
    navigate(getProductRequestDetaillUrl(tenantId, locationId, item.id));
  };

  const fetchStaffs = async () => {
    const response = await getStaffs(tenantId, locationId);
    setStaffs(response);
    fetchServiceRequestStatuses();
  };

  const onCancel = () => {
    setShowAssignForm(false);
  };

  const onClose = () => {
    setShowAssignForm(false);
  };

  const onAssign = (event, item) => {
    event.stopPropagation();
    setSelectedRequest(item);
    setShowAssignForm(true);
  };

  const assignStaff = async (item) => {
    setApiInProgress(true);
    const data = {
      id: selectedRequest.id,
      assignedTo: { id: item.id, user: { id: item.user.id } },
      status: { name: "ORDERED" },
      assignedBy: { id: localStorage.getItem("userId") },
      product: { name: selectedRequest.product.name },
      comment: selectedRequest.comment === null ? "" : selectedRequest.comment,
    };
    await assign(tenantId, locationId, data);
    setApiInProgress(false);
    setShowAssignForm(false);
    fetchServiceRequests();
  };

  useEffect(() => {
    fetchServiceRequests();
    // ProductPagination()
  }, [statusUpdate, alert, filteredStatus]);

  return (
    <VDiv>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ProductRequest
            filteredStatus={filteredStatus}
            onAssign={onAssign}
            onClick={goToDetail}
            statuses={statuses}
            // list={list}
            statusUpdate={statusUpdate}
            // pagination={pagination}

            setStatusUpdate={setStatusUpdate}
          />

          <HDiv>
            {showAssignForm && (
              <Popover
                title={"Select a staff"}
                onCancel={onCancel}
                onClose={onClose}
                saveLabel="Assign"
              >
                <div className="table-responsive text-nowrap h-80vh w-100">
                  <table className="table striped">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Services</th>
                        <th>Availability</th>
                        <th>Assign</th>
                      </tr>
                    </thead>
                    <tbody>
                      {staffs.map((item, index) => (
                        <tr key={index} className="v-align-center">
                          <td>{index + 1}</td>
                          <td>
                            {item.user.firstName} {item.user.lastName}
                          </td>
                          <td>{item.department ? item.department.name : ""}</td>
                          <td></td>
                          <td>Available</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-primary w-auto mb-0"
                              onClick={() => assignStaff(item)}
                            >
                              Assign
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {apiInProgress && <ProcessLoader />}
              </Popover>
            )}
          </HDiv>
        </>
      )}
    </VDiv>
  );
}

const PaginationIcons = ({  imageUrl, disabled, onClick }) => (
  <li
    className={` ${disabled ? "disabled" : ""}`}
    onClick={onClick}
  >
    {imageUrl && <div className="bg-light d-flex justify-content-center align-items-center" style={{borderRadius:"20px",height:"30px",width:"30px"}}><img src={imageUrl} alt="hi" height={15}/> </div>} 
  </li>
);

function ProductRequest({
  data,
  onClick,
  onAssign,
  no,
  statuses,
  // list,
  statusUpdate,
  setStatusUpdate,
  filteredStatus,
}) {
  const [selectedStatus, setSelectedStatus] = useState("");
  const { tenantId, locationId } = useParams();
  const [list, setList] = useState([]);

  const [pageNo, setPageNo] = useState(0);
  const [size, setSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  
  const ProductPagination = async (currentPage, size) => {
    
    const response = await getProductPagination(
      tenantId,
      locationId,
      currentPage,
      size,
      filteredStatus[1]
    );
    setList(response);

    setPageCount(Math.ceil(list.length / size));
  };


  
  const handlePageClick = async (data) => {
    
    let currentPage = data.selected;
    setPageNo(currentPage);
      ProductPagination(currentPage, size);
    
  };


  
  useEffect(() => {
    getProductPagination(tenantId, locationId, 0, 10, filteredStatus[1]).then(
      (response) => {
        setList(response);
        
        setPageCount(Math.ceil(response.length / size));
      }
    );
  }, []);
  const onStatusChange = async (event, item) => {
    setSelectedStatus(event.target.value);
    let status = statuses.filter((item) => {
      return item.id === event.target.value;
    });

    if (status.length > 0) {
      // setItem({ ...item, status: status[0] })
      if (status[0].name === "ORDERED") {
        onAssign(event, item);
      } else {
        const data = {
          id: item.id,
          status: { id: event.target.value },
          doneBy: { id: localStorage.getItem("userId") },
        };
        await updateStatus(tenantId, locationId, data);
        setStatusUpdate(!statusUpdate);
      }
    }
  };

  const columns = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {index + 1}
          {/* {setSelectedStatus(row.status.id)} */}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Product",
      id: "tableHeader",
      selector: (row) => row.product.name,
      sortable: false,
      width: "300px",
      cell: (row) => (
        <span
          className="avatar-container capitalize"
          onClick={() => onClick(row)}
        >
          <Avatar name={row.product.name} />
          <strong>{row.product.name}</strong>
        </span>
      ),
    },
    {
      name: "Request From",
      id: "tableHeader",
      selector: (row) => (row.room !== null ? row.room.name : ""),
      sortable: false,
      width: "200px",
      cell: (row) => (
        <span
          className="avatar-container capitalize"
          //   onClick={() => onClick(row)}
        >
          <div>
            {/* <span className="text-danger">
              {row.room.floorId.blockId.name +
                "/" +
                row.room.floorId.name +
                "/"}
            </span>
            <p>{row.room.name}</p> */}
            <span>
              <p className="text-danger fs-13px fw-bold">{row.room.name}</p>
              <p className="text-wrap">
                {"(" +
                  row.room.floorId.blockId.name +
                  "," +
                  row.room.floorId.name +
                  ")"}
              </p>
            </span>
          </div>
        </span>
      ),
    },
    {
      name: "Request On",
      id: "tableHeader",
      selector: (row) => (
        <span>{getFormattedDateAndTime(new Date(row.createdOn))}</span>
      ),
      sortable: false,
      width: "200px",
    },
    {
      name: "Assigned To",
      id: "tableHeader",
      selector: (row) =>
        row.assignedTo ? (
          row.assignedTo.user.firstName
        ) : (
          <button
            type="button"
            className="btn btn-primary w-auto mb-0"
            onClick={(event) => onAssign(event, row)}
          >
            Assign
          </button>
        ),
      sortable: false,
      width: "150px",
    },
    {
      name: "Change Status",
      id: "tableHeader",
      selector: (row) => (
        <div className="w-100">
          <StatusSelectGroup
            className={"w-100 "}
            labelProperty="displayName"
            showLabel={false}
            list={statuses}
            valueProperty={"id"}
            label={"Select status"}
            statusvalue={row.status.id ? row.status.id : selectedStatus}
            onChange={(event) => onStatusChange(event, row)}
          />
        </div>
      ),
      width: "160px",
      cell: (row) => (
        <div className="w-100">
          <StatusSelectGroup
            className={"w-100 bg-light"}
            labelProperty="displayName"
            showLabel={false}
            list={statuses}
            valueProperty={"id"}
            label={"Select status"}
            statusvalue={
              row.status && row.status.id ? row.status.id : selectedStatus
            }
            onChange={(event) => onStatusChange(event, row)}
          />
        </div>
      ),
      // sortable: true,
    },
  ];
  const columns2 = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`}>
          {" "}
          {index + 1}
          {/* {setSelectedStatus(row.status.id)} */}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Product",
      id: "tableHeader",
      selector: (row) => row.product.name,
      sortable: false,
      width: "300px",
      cell: (row) => (
        <span
          className="avatar-container capitalize"
          onClick={() => onClick(row)}
        >
          <Avatar name={row.product.name} />
          <strong>{row.product.name}</strong>
        </span>
      ),
    },
    {
      name: "Request From",
      id: "tableHeader",
      selector: (row) => (row.room !== null ? row.room.name : ""),
      sortable: false,
      width: "200px",
      cell: (row) => (
        <span
          className="avatar-container capitalize"
          //   onClick={() => onClick(row)}
        >
          <div>
            <span>
              <p className="text-danger fs-13px fw-bold">{row.room.name}</p>
              <p className="text-wrap">
                {"(" +
                  row.room.floorId.blockId.name +
                  "," +
                  row.room.floorId.name +
                  ")"}
              </p>
            </span>
          </div>
        </span>
      ),
    },
    {
      name: "Request On",
      id: "tableHeader",
      selector: (row) => (
        <span>{getFormattedDateAndTime(new Date(row.createdOn))}</span>
      ),
      sortable: false,
      width: "270px",
    },
    {
      name: "Assigned To",
      id: "tableHeader",
      selector: (row) =>
        row.assignedTo ? (
          row.assignedTo.user.firstName
        ) : (
          <button
            type="button"
            className="btn btn-primary w-auto mb-0"
            onClick={(event) => onAssign(event, row)}
          >
            Assign
          </button>
        ),
      sortable: false,
      width: "200px",
    },
  ];


  return (
    <div>
      {filteredStatus && list.length ? (
        filteredStatus[0] === "NEW" ? (
          <>
            <DataTable
              data={list}
              className="dataTable"
              onRowClicked={onClick}
              columns={columns2}
              // pagination
            />
            <div className="relative bg-white d-flex  justify-content-end align-items-center">
              <div className="d-flex justify-content-center align-items-center">
              <span className="mt-2">Rows per page:</span>
              <select
                className="border-0 mt-2"
                onChange={(e) => {
                  setSize(e.target.value);
                  ProductPagination(pageNo, e.target.value);
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
              </div>
              <ReactPaginate
                previousLabel={<PaginationIcons  imageUrl="/assets/images/icons/left-arrow.png"/>}
                nextLabel={<PaginationIcons  imageUrl="/assets/images/icons/right-arrow.png"/>}
                pageCount={Math.ceil(39/size)}
                onPageChange={handlePageClick}
                marginPagesDisplayed={0}
                pageRangeDisplayed={0}
                containerClassName={"d-flex gap-4 list-style-none mt-4 me-4"}
                previousClassName={`pagination-arrow ${
                  pageNo === 0 ? "disabled" : ""
                }`}
                nextClassName={`pagination-arrow ${
                  pageNo === pageCount ? "disabled" : ""
                }`}
              />
            </div>
          </>
        ) : (
          <>
            <DataTable
              data={list}
              className="dataTable"
              onRowClicked={onClick}
              columns={columns}
              // pagination
              // paginationPerPage={size}
              // paginationServer
              // paginationTotalRows={pagination.total || 0}
              // onChangeRowsPerPage={onSizeChange}
              // onChangePage={onPageChange}
            />
            <div className="relative bg-white d-flex  justify-content-end align-items-center">
              <div className="d-flex justify-content-center align-items-center">
              <span className="mt-2">Rows per page:</span>
              <select
                className="border-0 mt-2"
                onChange={(e) => {
                  setSize(e.target.value);
                  ProductPagination(pageNo, e.target.value);
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
              </div>
              <ReactPaginate
                previousLabel={<PaginationIcons  imageUrl="/assets/images/icons/left-arrow.png"/>}
                nextLabel={<PaginationIcons  imageUrl="/assets/images/icons/right-arrow.png"/>}
                pageCount={Math.ceil(39/size)}
                onPageChange={handlePageClick}
                marginPagesDisplayed={0}
                pageRangeDisplayed={0}
                containerClassName={"d-flex gap-4 list-style-none mt-4 me-4"}
                previousClassName={`pagination-arrow ${
                  pageNo === 0 ? "disabled" : ""
                }`}
                nextClassName={`pagination-arrow ${
                  pageNo === pageCount ? "disabled" : ""
                }`}
              />
            </div>
          </>
        )
      ) : (
        <div className="d-flex justify-content-center">
          <p>No Data Found</p>
        </div>
      )}
    </div>
  );
}
