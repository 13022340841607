import { 
    getRoomsApiUrl,
    getSaveRoomApiUrl,
    getRoomApiUrl,
    getSaveRoomServicesApiUrl,
    getRoomServicesApiUrl,
    getDeleteRoomApiUrl,
    getRoomTypesApiUrl
 } from "./ApiUrls";
import { post, get, put, Delete } from "./Service";


export const getRooms = async (tenantId, locationId, floorId) => {
    const response = await get(getRoomsApiUrl(tenantId, locationId, floorId));
    if(!response) {
        return []
    }
    return response;
};

export const getRoomTypes = async (tenantId, locationId) => {
    const response = await get(getRoomTypesApiUrl(tenantId, locationId));
    if(!response) {
        return []
    }
    return response;
};


export const getRoom = async (tenantId, locationId, floorId, roomId) => {
    const response = await get(getRoomApiUrl(tenantId, locationId, floorId, roomId));
    if(!response) {
        return []
    }
    return response;
};

export const saveRoom = async (tenantId, locationId, floorId, data) => {
    const response = await post(getSaveRoomApiUrl(tenantId, locationId, floorId), data);
    return response;
};

export const updateRoom = async (tenantId, locationId, floorId, data) => {
    const response = await put(getSaveRoomApiUrl(tenantId, floorId, locationId), data);
    return response;
};

export const deleteRoom = async (tenantId, locationId, floorId, id) => {
    const response = await Delete(getDeleteRoomApiUrl(tenantId, locationId, floorId, id));
    return response;
};

export const saveRoomServices = async (tenantId, locationId, roomId, data) => {
    const response = await post(getSaveRoomServicesApiUrl(tenantId, locationId, roomId), data);
    return response;
};

export const getRoomServices = async (tenantId, locationId, roomId) => {
    const response = await get(getRoomServicesApiUrl(tenantId, locationId, roomId));
    return response;
};

