import React from "react";
import Card from "../components/cards/card";
import Loader from "../components/general/loader";
import NoDataMessage from "../components/general/NoDataMessage";
import Layout from "../components/layout/layout";
import useStaffs from "../hooks/useStaffs";
import StaffPopup from "../components/popups/staff-form-popup";
import Popover from "../components/popups/popover";
import Toast from "../components/general/toast";
import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";
import Avatar from "../components/general/avatar";
import DataTable from "react-data-table-component";
export default function Staffs() {
  const {
    showForm,
    list,
    formData,
    handleSubmit,
    loading,
    handleAddClick,
    handleCancelClick,
    handleDeleteClick,
    handleEditClick,
    departments,
    handleRowClick,
    showMessage,
    showConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    message,
    selectedItem,
    setEdit,
  } = useStaffs();
  const columns = [
    {
      name: "S. No",
      id: "tableHeader",
      cell: (row, index) => (
        <span className={`${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}
        >
          {" "}
          {index + 1}
        </span>
      ),
      width: "80px",
      center: "true",
    },
    {
      name: "Name",
      id: "tableHeader",
      selector: (row) => row.user.firstName,
      sortable: false,
      cell: (row) => (
        <span
          className={`avatar-container capitalize ${row.deleted ? "text-danger" : ""}`}
          onClick={() => handleRowClick(row)}
        >
          <Avatar image={row.imageUrl} name={row.user.firstName} />{" "}
          <strong>{row.user.firstName}</strong>
        </span>
      ),
    },
    {
      name: "Department",
      id: "tableHeader",
      selector: (row) => (
        <span className={`capitalize ${row.deleted ? "text-danger" : ""}`} onClick={() => handleRowClick(row)}
        >
          {" "}
          {row.department !== null ? row.department.name : ""}
        </span>
      ),
      sortable: false,
    },
    {
      name: "Edit",
      id: "tableHeader",
      selector: (row) =>
        !row.deleted && (
          <EditButton
            onClick={(event) => {
              handleEditClick(event, row);
              setEdit(true);
            }}
          />
        ),
      width: "80px",
      center: "true",
    },
    {
      name: "Delete",
      id: "tableHeader",
      selector: (row) =>
        !row.deleted && (
          <DeleteButton onClick={(event) => handleDeleteClick(event, row)} />
        ),
      width: "80px",
      center: "true",
    },
  ];
  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card
            title="Staffs manager"
            subTitle="Add/Edit/Delete your staffs."
            modalId="masterTypeModal"
            showAddButton
            onAdd={handleAddClick}
          >
            <div className="row">
              {list && list.length > 0 ? (
                <DataTable
                  data={list}
                  columns={columns}
                  pagination
                  onRowClicked={handleRowClick}
                  className="dataTable"
                />
              ) : (
                <NoDataMessage
                  modalId="masterTypeModal"
                  showAddButton
                  onAdd={handleAddClick}
                />
              )}
            </div>
          </Card>
          {showForm && (
            <StaffPopup
              departments={departments}
              onCancel={handleCancelClick}
              onSave={handleSubmit}
              formData={formData}
              title="Staff"
            />
          )}
        </>
      )}
      {showConfirmation && (
        <Popover
          title={`Confirm deleting '${selectedItem.user.firstName} ${selectedItem.user.lastName}'`}
          showActions
          saveLabel="Delete"
          onCancel={onDeleteCancel}
          onClose={onDeleteCancel}
          onSave={onDeleteConfirm}
        >
          <p className="mt-1">{`Are you sure that you want to delete staff '${selectedItem.user.firstName} ${selectedItem.user.lastName}'?`}</p>
        </Popover>
      )}
      {showMessage && <Toast text={message} className="success" />}
    </Layout>
  );
}
