import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCategory } from "../service/CategoryService";

export default function useCategoryDetail() {
  const [category, setCategory] = useState([]);

  const [formData, setFormData] = useState(undefined);

  const [loading, setLoading] = useState(true);

  const { tenantId, locationId, categoryId } = useParams();

  const [showForm, setShowForm] = useState(false);

  const handleSubmit = async (response, saveAndAddAnother) => {
    if (!saveAndAddAnother) {
      setShowForm(false);
    }
    fetchCategory();
  };

  const fetchCategory = async () => {
    const response = await getCategory(tenantId, locationId, categoryId);
    setCategory(response);
    setLoading(false);
  };

  const handleAddClick = () => {
    setShowForm(true);
  };

  const handleEditClick = (row) => {
    setFormData(row);
    setShowForm(true);
  };

  const handleDeleteClick = (row) => {};

  const handleCancelClick = () => {
    setShowForm(false);
  };

  useEffect(() => {
    fetchCategory();
  }, [locationId, showForm]);

  return {
    showForm,
    formData,
    handleSubmit,
    category,
    loading,
    handleAddClick,
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
    setShowForm,
  };
}
